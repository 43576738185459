import React from 'react';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import Heading from './Heading';
import Text from './Text';
export default function EmptyState({ image, title = 'No results just yet', description = (React.createElement(React.Fragment, null,
    "Nobody has completed the test yet. ",
    React.createElement("br", null),
    " Come back to check later!")), testid = 'empty-state', }) {
    return (React.createElement(Container, { "data-testid": testid },
        image,
        React.createElement(Heading, { size: "l" }, title),
        React.createElement(Text, { as: "p", size: "m", fontSize: 14 }, description)));
}
export const Container = styled(Box) `
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  p {
    margin-top: ${(props) => props.theme.space.xxs}px;
  }
`;
export const EmptyStateWrapper = styled(Box).attrs((props) => ({
    borderRadius: 1,
    px: 4,
    py: 6,
    border: '1px dashed',
    borderColor: 'smoke',
    height: props.height,
})) `
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;
