import React from 'react';
import { Box, ModalDialog } from '@marvelapp/ui';
import DeleteButton from './DeleteButton';
function DeleteModal({ buttonText, isOpen, trigger, title = 'Are you sure?', children, handleFormSubmit, hideConfirmationForm, }) {
    return (React.createElement(ModalDialog, { isOpen: isOpen, title: title, trigger: trigger },
        React.createElement(Box, { p: 25 },
            children,
            hideConfirmationForm ? null : (React.createElement(DeleteButton, { buttonText: buttonText, handleFormSubmit: handleFormSubmit })))));
}
export default DeleteModal;
