export const DASHBOARD_YEARLY = 'dashboard-yearly';
export const DASHBOARD_YEARLY_SPECIAL = 'dashboard-yearly-special';
export const DASHBOARD_YEARLY_SPECIAL_TEAM = 'dashboard-yearly-special-team';
export const DASHBOARD_FIRST_PROJECT = 'dashboard-first-project';
export const DASHBOARD_FEEDBACK = 'dashboard-feedback';
export const DASHBOARD_USER_TESTING_EBOOK = 'dashboard-user-testing-ebook';
export const DASHBOARD_COMMENTS_FEEDBACK = 'dashboard-comments-feedback';
export const DASHBOARD_WEBINAR = 'dashboard-webinar';
export const DASHBOARD_REACHED_PROJECT_LIMIT = 'dashboard-reached-project-limit';
export const DASHBOARD_PLG_CONTENT = 'dashboard-plg-content';
export const DASHBOARD_WHITEBOARD_BETA = 'dashboard-whiteboard-beta';
export const DETACHED_PROJECTS = 'detached-projects';
export const ACCOUNT_YEARLY = 'account-yearly';
export const STUNNING = 'stunning';
export const PROTOTYPE_TUTORIAL = 'prototype-tutorial';
export const USER_TESTING_TUTORIAL = 'user-testing-tutorial';
export const ENTERPRISE_RENEW = 'enterprise-renew';
export const FREE_TRIAL = 'free-trial';
export const TYPEFORM = 'typeform';
export const ROLE_QUESTION = 'role_question';
export const WEBINAR = 'webinar';
export const DASHBOARD_CONTENT_CAROUSELS = 'content_carousels';
export const DASHBOARD_CONTENT_REMOTE_USABILITY = 'content_remote_usability';
export const DASHBOARD_CONTENT_DESIGN_THINKING = 'content_design_thinking';
export const DASHBOARD_CONTENT_PITCH_PRESENT = 'content_pitch_present';
export const DASHBOARD_CONTENT_DESIGN_LED = 'content_design_led';
