import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Flex } from '@marvelapp/ui';
import Heading from './Heading';
import Link from './Link';
import Logo from './Logo';
import Text from './Text';
export default function BaseError({ heading, headingMaxWidth = 500, subheading, subheadingMaxWidth = 500, testId, }) {
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, heading)),
        React.createElement(Flex, { alignItems: "flex-start", flexDirection: "column", justifyContent: ['center', 'center', 'space-between', 'space-between'], m: "0 auto", maxWidth: "1000px", minHeight: "100vh", px: 4, py: [4, 4, 5, 5] },
            React.createElement(Link, { href: "/", target: "_self" },
                React.createElement(Logo, null)),
            React.createElement(Flex, { alignItems: "center", flexDirection: ['column', 'column', 'row', 'row'] },
                React.createElement(Text, { tag: "div" },
                    React.createElement(Heading, { as: "h1", fontSize: [5, 6, 7, 7], fontWeight: "bold", maxWidth: headingMaxWidth, marginBottom: "s", testId: testId }, heading),
                    React.createElement(Text, { as: "p", color: "slate", size: "l", maxWidth: subheadingMaxWidth }, subheading))),
            React.createElement(Box, null))));
}
