/* eslint-env browser */
export const key = 'upsells';
export function getClosedUpsells({ userPk }) {
    try {
        const closedUpsells = JSON.parse(window.localStorage.getItem(key) || '[]');
        const now = new Date().getTime();
        return closedUpsells
            .filter((upsell) => upsell.expireTime > now)
            .filter((upsell) => upsell.userPk === userPk);
    }
    catch (error) {
        console.log(error);
        return [];
    }
}
export function setClosedUpsell({ identifier, userPk, position, daysUntilReshownAfterClose, }) {
    const closed = getClosedUpsells({ userPk });
    // This can never be below 2 days as there has to be at least 1 day
    // inbetween showing upsells in a position therefore this could
    // trigger a situation that the banners coming after this one never get shown.
    let daysToExpiry = daysUntilReshownAfterClose;
    if (daysToExpiry < 2) {
        console.log(`Days until expired can't be less than 1 days. See the code. (${identifier})`);
        daysToExpiry = 2;
    }
    const now = new Date();
    const futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + daysToExpiry);
    const expireTime = futureDate.getTime();
    const createdTime = now.getTime();
    closed.push({
        identifier,
        expireTime,
        createdTime,
        userPk,
        position,
    });
    try {
        window.localStorage.setItem(key, JSON.stringify(closed));
        return closed;
    }
    catch (error) {
        console.log(error);
        return [];
    }
}
export function canBeShownAgain({ identifier, closed, position }) {
    // Check if closed before
    const closedWithIdentifier = closed.filter((el) => el.identifier === identifier);
    if (closedWithIdentifier.length > 0) {
        return false;
    }
    // Check if anything shown in the position in the past 24h
    const oneDayAgo = new Date(Date.now());
    oneDayAgo.setDate(oneDayAgo.getDate() - 1);
    const oneDayAgoTime = oneDayAgo.getTime();
    const closedInThePastDay = closed.filter((el) => {
        return el.position === position && el.createdTime > oneDayAgoTime;
    });
    if (closedInThePastDay.length > 0) {
        return false;
    }
    return true;
}
