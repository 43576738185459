import themeGet from '@styled-system/theme-get';
import styled, { createGlobalStyle } from '@marvelapp/styled';
import { CLOSE_ICON, colors } from '@marvelapp/theme';
import { Icon, hexToRGB } from '@marvelapp/ui';
const black09 = hexToRGB(colors.black, 0.9);
export const ToastCloseWrap = styled.div `
  position: absolute;
  right: -10px;
  top: -10px;
  background: white;
  border-radius: 50%;
  opacity: 0;
  transition: 250ms ease-in-out;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
`;
export const ToastCloseButton = styled(Icon).attrs({
    paths: CLOSE_ICON,
    display: 'block',
    width: 20,
    height: 20,
}) `
  transition: 250ms ease-in-out;
  fill: ${themeGet('colors.silver')};

  &:hover {
    fill: ${themeGet('colors.slate')};
  }
`;
// More classes available for the toast message can be found here:
// https://github.com/fkhadra/react-toastify/blob/master/dist/ReactToastify.css
export const ToastContainerElement = styled.div `
  .Toastify__toast-container {
    padding: 8px;
    width: 400px;
    bottom: 0;
    right: 1em;
    height: min-content;
  }

  .Toastify__toast {
    overflow: visible;
    display: inline-block;
    min-height: 0px;
    border-radius: 6px;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.3;
    margin-bottom: 20px;
    padding: 16px;
    padding-right: 20px;
    box-shadow: ${themeGet('shadows.toast')};
    color: ${themeGet('colors.white')};
    background-color: ${black09};

    .Toastify__progress-bar {
      background: ${themeGet('colors.snowDark')};
    }

    &:hover {
      ${ToastCloseWrap} {
        opacity: 1;
      }
    }
  }
`;
// The easiest way to define a custom transition for the toasties is with global styles
// https://github.com/fkhadra/react-toastify#replace-the-default-transition
export const GlobalTransitionStyles = createGlobalStyle `
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translate3d(-200px, 0, 0px);
    }

    50% {
      opacity: 1;
    }
  }

  .slideIn {
    animation-name: slideIn;
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }

  @keyframes slideOut {
    from {
      opacity: 1;
    }

    50% {
      opacity: 0;
      transform: translate3d(-100px, 0, 0px);
    }

    to {
      opacity: 0;
    }
  }

  .slideOut {
    animation-name: slideOut;
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
`;
