import { __rest } from "tslib";
import React from 'react';
import { Badge as MarvelBadge } from '@marvelapp/ui';
const kinds = {
    positive: {
        bg: 'grass4',
        color: 'grass12',
        border: 'none',
    },
    negative: {
        bg: 'red4',
        color: 'red12',
        border: 'none',
    },
    sky: {
        bg: 'sky5',
        color: 'sky12',
        border: 'none',
    },
    amber: {
        bg: 'amber5',
        color: 'amber12',
        border: 'none',
    },
    slate5: {
        bg: 'slate5',
        color: 'slate12',
        border: 'none',
    },
    blue3: {
        bg: 'blue3',
        border: '1px solid',
        borderColor: 'blue6',
        color: 'blue12',
    },
    blue10: {
        bg: 'blue10',
        border: 'none',
        color: 'background',
    },
    primary: {
        bg: 'primary',
        color: 'background',
        border: 'none',
    },
    ghost: {
        border: '1px solid',
        borderColor: 'smoke',
        color: 'slate12',
    },
};
export default function Badge(_a) {
    var { children, kind = 'slate5', testId } = _a, rest = __rest(_a, ["children", "kind", "testId"]);
    return (React.createElement(MarvelBadge, Object.assign({ "data-testid": testId }, kinds[kind], rest), children));
}
