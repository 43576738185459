import shouldForwardProp from '@styled-system/should-forward-prop';
import { pickBy } from 'lodash-es';
export function hexToRGB(hex, alpha) {
    if (!hex) {
        return null;
    }
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    if (alpha) {
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
    return `rgb(${r}, ${g}, ${b})`;
}
export function loadImage(url) {
    return new Promise((resolve, reject) => {
        const img = new window.Image();
        img.onload = resolve;
        img.onerror = reject;
        img.src = url;
    });
}
export function getDOMProps(props) {
    return pickBy(props, (_, propName) => shouldForwardProp(propName));
}
