import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import Upsell from './components/Upsell';
import * as positions from './positions';
export function DashboardTinyUpseller() {
    return React.createElement(Upsell, { position: positions.DASHBOARD_TINY_UPSELLER });
}
export function DashboardNotificationBar() {
    return React.createElement(Upsell, { position: positions.DASHBOARD_NOTIFICATION_BAR });
}
export function DashboardHintBarPersonalProjects() {
    return React.createElement(Upsell, { position: positions.DASHBOARD_HINT_BAR_PERSONAL_PROJECTS });
}
export function FloatingUpsell() {
    const location = useLocation();
    const allowedPaths = [
        '/projects/my',
        '/projects/team',
        '/projects/personal',
        '/folders/my',
        '/folders/team',
        '/folder/:id',
        '/archive/my',
        '/archive/team',
        '/company/people/members',
        '/company/people/guests',
        '/company/groups',
        '/company/settings',
        '/user/account/personal',
        '/user/account/apps',
    ];
    const excludedPaths = ['/folder/new'];
    const isAllowed = allowedPaths
        .map((path) => {
        return !!matchPath(location.pathname, {
            path,
            exact: true,
        });
    })
        .includes(true);
    const isExcluded = excludedPaths
        .map((path) => {
        return !!matchPath(location.pathname, {
            path,
            exact: true,
        });
    })
        .includes(true);
    return isAllowed && !isExcluded && React.createElement(Upsell, { position: positions.FLOATING });
}
export function DashboardHintBar() {
    return React.createElement(Upsell, { position: positions.DASHBOARD_HINT_BAR });
}
export function AccountHintBar() {
    return React.createElement(Upsell, { position: positions.ACCOUNT_HINT_BAR });
}
export function PrototypeHintBar() {
    return React.createElement(Upsell, { position: positions.PROTOTYPE_HINT_BAR });
}
export function FreeTrialNotificationBar() {
    return React.createElement(Upsell, { position: positions.FREE_TRIAL_NOTIFICATION_BAR });
}
export function Webinar() {
    return React.createElement(Upsell, { position: positions.DASHBOARD_SIDE_BAR });
}
export { setViewedPricingPage, setDidDowngrade } from './storage-assist';
