import React from 'react';
import { Badge } from '@marvelapp/ui';
import { Link } from '@marvelapp/ui-internal';
function UpgradeBadge() {
    return (React.createElement(Link, { "data-testid": "dashboard-upgrade-badge", marginRight: 2, to: { pathname: '/plans', state: { modal: true } } },
        React.createElement(Badge, { fontSize: "xxs", fontWeight: 600, hoverBg: "blue3", hoverColor: "blue11", kind: "marvel", marginLeft: "-2px" },
            React.createElement("span", { role: "img", "aria-label": "Tada" }, "\uD83C\uDF89"),
            ' ',
            "Start free trial")));
}
export default UpgradeBadge;
