import { browserName, browserVersion, isAndroid, isChrome, isDesktop, isEdge, isFirefox, isIOS, isMacOs, isMobile, isMobileOnly, isOpera, isSafari, isTablet, mobileModel, osName, osVersion, } from 'react-device-detect';
// https://github.com/duskload/react-device-detect#readme
const isIPad = isTablet && isIOS;
const isIPhone = isMobileOnly && isIOS;
export const deviceDetection = {
    browserVersion,
    isIPad,
    isIPhone,
    isAndroid,
    isIOS,
    isMobile,
    isMacOs,
    isDesktop,
    isChrome: isChrome || browserName === 'Chrome Headless',
    isOpera,
    isSafari,
    isFirefox,
    isEdge,
    // Returns false if a tablet.
    isMobileOnly,
    // Windows, Android...
    osName,
    // 7
    osVersion,
    // Chrome, Safari...
    browserName,
    // Nexus 5
    mobileModel,
};
