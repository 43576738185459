/* eslint-disable prefer-destructuring */
import { breakpoints, easings, fonts, transitions } from '@marvelapp/theme';
import { colors } from './colors';
import { shadows } from './shadows';
import { radii, sizes, space } from './sizes';
import { fontSizes, fontWeights, lineHeights } from './typography';
import { zIndices } from './zIndices';
export const theme = {
    breakpoints,
    colors,
    easings,
    fonts,
    fontSizes,
    fontWeights,
    lineHeights,
    radii,
    shadows,
    sizes,
    space,
    transitions,
    zIndices,
};
// Set up font size aliases
theme.fontSizes.body = theme.fontSizes[3];
theme.fontSizes.xxs = theme.fontSizes[0];
theme.fontSizes.xs = theme.fontSizes[1];
theme.fontSizes.s = theme.fontSizes[2];
theme.fontSizes.m = theme.fontSizes[3];
theme.fontSizes.l = theme.fontSizes[4];
theme.fontSizes.xl = theme.fontSizes[5];
theme.fontSizes.xxl = theme.fontSizes[6];
theme.fontSizes.xxxl = theme.fontSizes[7];
theme.fontSizes.xxxxl = theme.fontSizes[8];
theme.fontSizes.xxxxxl = theme.fontSizes[9];
theme.fontSizes.xxxxxxl = theme.fontSizes[10];
theme.fontSizes.xxxxxxxl = theme.fontSizes[11];
// Set up space aliases
theme.space.xxs = theme.space[1];
theme.space.xs = theme.space[2];
theme.space.s = theme.space[3];
theme.space.m = theme.space[4];
theme.space.l = theme.space[5];
theme.space.xl = theme.space[6];
theme.space.xxl = theme.space[7];
theme.space.xxxl = theme.space[8];
theme.space.wideSidebar = theme.sizes.wideSidebar;
// Set up radii aliases
theme.radii.xs = theme.radii[1];
theme.radii.s = theme.radii[2];
theme.radii.m = theme.radii[3];
theme.radii.l = theme.radii[4];
theme.radii.xl = theme.radii[5];
theme.radii.round = '99px';
theme.radii.circle = '50%';
