import { __awaiter } from "tslib";
/* GLOBALS __DEV__ */
import gql from 'graphql-tag';
import { flowRight, get } from 'lodash-es';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import { graphql, useQuery, withApollo } from 'react-apollo';
import Helmet from 'react-helmet';
import { NetworkStatusCode, PlanLabel, UserRole, camelCase, fetchApi, heapTrackEvent, hubspotIdentify, hubspotInitialize, hubspotTrackEvent, isDebugMode, } from '@marvelapp/core';
import { useLogRocket } from '@marvelapp/hooks';
import { PermissionsProvider } from '@marvelapp/marvel-3-application';
import { FullScreenLoader } from '@marvelapp/ui-internal';
import config from '../config';
import applyUserOverrides from '../utils/applyUserOverrides';
import { LaunchDarklyProvider, getFlags, getLaunchDarklyClient, } from '../utils/launchDarkly';
import logout from '../utils/logout';
import { connectPusher } from '../utils/pusher';
import { capitliseAndLowercaseString } from '../utils/stringUtil';
import App from './App';
const USER_ON_FREE_TRIAL = '000009355999';
const isProduction = process.env.NODE_ENV === 'production';
let DevToolbar;
if (isDebugMode()) {
    // This makes sure it's not included in the bundle in a production deploy
    DevToolbar = React.lazy(() => import(/* webpackChunkName: "DevToolbar" */ './DevToolbar'));
}
const USER_QUERY = gql `
  query getUser {
    user {
      pk
      avatarUrl
      username
      createdAt
      email
      firstName
      lastName
      occupation
      hasHadTeamTrial
      occupationDisplayName
      emailAddressType
      lastActiveAt
      image: avatarUrl
      isDetached
      isSketch
      testGroup
      tool
      signedToken {
        token
        marvelUserId
      }
      properties {
        prototypeProjectsOwnedCount
        userTestProjectsOwnedCount
        screensOwnedCount
        hotspotsOwnedCount
        teamsOwnedCount
        foldersOwnedCount
      }
      company {
        pk
        createdAt
        name
        iconUrl
        prototypeProjectCount
        userTestProjectCount
        testResultsCount
        memberCount
        guestCount
        accountLockOverride
        owner {
          pk
          email
          username
        }
        billing {
          subscriptionPk
          projectQuantityPurchased
          billingCycle
          customerId
          nextPaymentDate
          trialDaysRemaining
          plan {
            label
          }
          accountId
          currency
        }
        settings {
          visibilityLock
          editorsCanInvite
        }
        flags {
          unrestrictedProjectTest
        }
      }
      role
    }
  }
`;
const GET_ADMIN_BILLING_DATA = gql `
  query getBilling {
    user {
      pk
      company {
        pk
        billing {
          subscriptionPk
          payment
          seats {
            used
            limit
            originallyPurchased
          }
        }
      }
    }
  }
`;
function AppContainer({ accessToken, client, data: { loading = false, networkStatus, user = {
    pk: null,
    username: null,
    email: null,
    role: 'ANONYMOUS',
}, refetch, } = {}, }) {
    const isLoading = loading && networkStatus !== NetworkStatusCode.Refetch;
    const [ldClient, setLdClient] = useState(null);
    const pusher = useRef(null);
    const [transactionalKey, setTransactionalKey] = useState('');
    const [isFetchingSubscription, setIsFetchingSubscription] = useState(false);
    const [subscription, _setSubscription] = useState(null);
    const setSubscription = (sub, { resetStore = true } = {}) => __awaiter(this, void 0, void 0, function* () {
        _setSubscription(camelCase(sub));
        if (resetStore) {
            yield client.resetStore();
        }
    });
    useEffect(() => {
        if (accessToken) {
            setIsFetchingSubscription(true);
            fetchApi('/api/user/', { accessToken })
                .then((response) => response.json())
                .then(({ transaction_key: transKey, subscription: newSubscription }) => {
                setSubscription(newSubscription, { resetStore: false });
                setTransactionalKey(transKey);
            })
                .finally(() => setIsFetchingSubscription(false));
        }
        else {
            setIsFetchingSubscription(false);
        }
    }, [accessToken]);
    useEffect(() => {
        if (accessToken) {
            hubspotInitialize(config.hubspot.id);
            // initialize and authenticate pusher
            pusher.current = connectPusher({
                apiKey: config.pusher.apiKeyUS,
                accessToken,
            });
        }
    }, [accessToken]);
    const { data: adminBillingData, loading: isAdminBillingDataLoading } = useQuery(GET_ADMIN_BILLING_DATA, {
        skip: ![UserRole.Owner, UserRole.Admin].includes(user.role),
    });
    const userObj = applyUserOverrides(user, adminBillingData);
    const { company, email, firstName, lastName, pk, role, properties, occupationDisplayName, testGroup, isSketch, emailAddressType, tool, } = userObj;
    const bgColor = /^\/project\/\d+\/screen\/\d+/.test(window.location.pathname)
        ? '#273444' // TODO: update colour palette in old styleguide / editor UI so that we can use our new theme here
        : 'white';
    const enableForAll = config.usertest.enableBallparkFeatureFlagForAll;
    useEffect(() => {
        // wait for first load otherwise user might not be able to access the feature/page
        if (!isLoading) {
            getLaunchDarklyClient({
                company,
                email,
                pk,
                role,
            }, enableForAll).then(setLdClient);
        }
    }, [isLoading, company, email, pk, role, testGroup]);
    useEffect(() => {
        if (email) {
            // identify the user to hubspot
            let planLabel = get(company, 'billing.plan.label');
            // Hubspot needs the plan label to be normalised with uppercase and lowercase
            planLabel =
                planLabel.charAt(0).toUpperCase() + planLabel.slice(1).toLowerCase();
            hubspotIdentify({
                email,
                firstname: firstName,
                lastname: lastName,
                current_plan: planLabel,
                current_plan_billing_cycle: get(company, 'billing.billingCycle'),
                role: role.charAt(0).toUpperCase() + role.slice(1).toLowerCase(),
                billing_cycle: get(company, 'billing.billingCycle'),
                number_of_projects: properties.prototypeProjectsOwnedCount,
                number_of_images: properties.screensOwnedCount,
                user_id: pk,
                job_role__c: occupationDisplayName,
                test_group_number: testGroup,
                sketch_integration: isSketch,
                emailAddressType,
                design_tool: tool,
            });
        }
    }, [email, occupationDisplayName, tool]);
    useEffect(() => {
        if (pk && window.heap) {
            window.heap.identify(pk);
            window.heap.addUserProperties({
                role,
                plan_label: get(company, 'billing.plan.label'),
                company_id: get(company, 'pk'),
                company_name: get(company, 'name'),
                occupation: occupationDisplayName,
                is_sketch: isSketch,
                design_tool: tool,
            });
        }
        if (get(company, 'flags.unrestrictedProjectTest')) {
            hubspotTrackEvent(USER_ON_FREE_TRIAL);
            heapTrackEvent('UserOnFreeTrial');
        }
    }, [pk, company, role]);
    useEffect(() => {
        var _a;
        const eligblePlans = [
            PlanLabel.Enterprise,
            PlanLabel.Company,
            PlanLabel.Team,
        ];
        const planLabel = get(company, 'billing.plan.label');
        const isEligibleLabel = eligblePlans.includes(planLabel);
        if (window.salesmachine &&
            isEligibleLabel &&
            !isAdminBillingDataLoading &&
            isProduction) {
            // Only sync to salesmachine if it's an Enterprise account and
            // The Marvel account for the time being
            const accountId = ((_a = company === null || company === void 0 ? void 0 : company.billing) === null || _a === void 0 ? void 0 : _a.accountId) || (company === null || company === void 0 ? void 0 : company.pk);
            if (accountId) {
                trackSalesmachineAccount(user, adminBillingData, accountId, planLabel);
                trackSalesmachineContact(accountId, user);
            }
        }
    }, [user, company, isAdminBillingDataLoading, adminBillingData]);
    useLogRocket({ user: userObj, alwaysShow: true, ignoreMarvel: false });
    // wait for initial load and flags to be ready otherwise user will get routed to sign in if this is a authenticated route
    // We are also waiting for isAdminBillingDataLoading to ensure the user object is completely ready before rendering the app
    if (isLoading ||
        isFetchingSubscription ||
        isAdminBillingDataLoading ||
        !ldClient)
        return React.createElement(FullScreenLoader, { bg: bgColor });
    const flags = getFlags(ldClient);
    return (React.createElement(PermissionsProvider, { user: userObj },
        React.createElement(LaunchDarklyProvider, { client: ldClient },
            React.createElement(React.Fragment, null,
                React.createElement(Helmet, { defaultTitle: "Ballpark", titleTemplate: "%s | Ballpark" }),
                React.createElement(App, { accessToken: accessToken, config: config, flags: flags, logout: logout, pusher: pusher.current, refetchUserData: refetch, setSubscription: setSubscription, subscription: subscription, transactionalKey: transactionalKey, user: userObj }),
                DevToolbar && (React.createElement(Suspense, { fallback: null },
                    React.createElement(DevToolbar, { user: user })))))));
}
function trackSalesmachineAccount(user, adminBillingData, accountId, label) {
    const { company } = user;
    const { name: companyName, memberCount, guestCount } = company;
    const { prototypeProjectCount, userTestProjectCount, testResultsCount } = company;
    const data = {
        name: companyName,
        members_count: memberCount,
        guests_count: guestCount,
        plan_type: capitliseAndLowercaseString(label),
        prototype_project_count: prototypeProjectCount,
        user_test_project_count: userTestProjectCount,
        test_results_count: testResultsCount,
    };
    if (adminBillingData) {
        const { originallyPurchased, used } = adminBillingData.user.company.billing.seats;
        data.seats_utilisation = Math.round((used / originallyPurchased) * 100);
        data.seats_utilisation_incl_guests = Math.round(((used + guestCount) / originallyPurchased) * 100);
    }
    window.salesmachine.account(accountId, data);
}
function trackSalesmachineContact(accountId, user) {
    const { email, pk, username } = user;
    window.salesmachine.contact(pk, {
        name: username,
        email,
        account_uid: accountId,
    });
}
export default flowRight(graphql(USER_QUERY, {
    skip: (props) => !props.accessToken,
}), withApollo)(AppContainer);
