import React from 'react';
import { Logo } from '@marvelapp/askhub-components';
import { Box, Button, Flex, Link, Text } from '@marvelapp/ui';
import { Page } from '@marvelapp/ui-internal';
function ProjectUnavailable({ status }) {
    // Important to set this because embeds are transparent, this avoids the project looking odd when embedded on a red page.
    const isEmbedded = window.top !== window.self;
    const backgroundColor = isEmbedded ? 'snow' : 'white';
    return (React.createElement(Page, { title: `This project is ${status}` },
        React.createElement(Flex, { alignItems: "center", bg: backgroundColor, flexDirection: "column", justifyContent: "center", maxWidth: "100vw", minHeight: "100vh", px: 4, py: [4, 4, 5, 5] },
            React.createElement(Link, { href: "/", target: "_self" },
                React.createElement(Logo, null)),
            React.createElement(Flex, { alignItems: "center", flexDirection: "column", justifyContent: "center", maxWidth: "90%" },
                React.createElement(Text, { color: "licorice", fontSize: [2, 3, 4, 5], fontWeight: 500, mb: 2, textAlign: "center" },
                    "This project is ",
                    status),
                React.createElement(Text, { color: "black", fontSize: [1, 2, 3, 3], maxWidth: 345, mb: 4, textAlign: "center" }, "Ask the owner to verify the share link and/or update permissions to play this project."),
                React.createElement(Button, { as: Link, "data-testid": "archived-page-cta", kind: "primary", to: "https://ballparkhq.com" }, "Visit Ballpark")),
            React.createElement(Box, null))));
}
export function ProjectLocked() {
    return React.createElement(ProjectUnavailable, { status: "locked" });
}
export function ProjectArchived() {
    return React.createElement(ProjectUnavailable, { status: "archived" });
}
