import { __rest } from "tslib";
import React, { forwardRef } from 'react';
import Loader from '../Loader';
import Position from '../Position';
import { inputKinds } from './kinds';
import { inputSizes, loaderSizes } from './sizes';
import { InputElement, LoaderWrapper, inputStyles } from './styles';
/** @type {any} */
const Input = forwardRef((_a, ref) => {
    var { border = 'none', disabled, display, hasTransition = true, hideArrows = false, isLoading, kind, positionWidth, required, size, tag } = _a, props = __rest(_a, ["border", "disabled", "display", "hasTransition", "hideArrows", "isLoading", "kind", "positionWidth", "required", "size", "tag"]);
    const stateProps = (isLoading || disabled) && Object.assign(Object.assign({}, inputKinds.disabled), { disabled: true, pointerEvents: 'none' });
    /* Order of props is important here because the last two objects contain
     * stateful props which should overwrite all the rest */
    const allProps = Object.assign(Object.assign(Object.assign(Object.assign({}, inputSizes[size]), inputKinds[kind]), props), stateProps);
    return (React.createElement(Position, { display: display, position: "relative", width: positionWidth },
        React.createElement(InputElement, Object.assign({ as: tag, border: border, hasTransition: hasTransition, hideArrows: hideArrows, ref: ref, required: required }, allProps, { display: display })),
        isLoading && (React.createElement(LoaderWrapper, { height: inputSizes[size].height, width: inputSizes[size].height },
            React.createElement(Loader, Object.assign({}, loaderSizes[size]))))));
});
/** @type {any} */
Input.defaultProps = {
    disabled: false,
    display: 'block',
    fontFamily: 0,
    fontWeight: 400,
    isLoading: false,
    kind: 'default',
    size: 1,
};
export default Input;
export { inputStyles };
