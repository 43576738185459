const sizes = [
    {
        borderRadius: 10,
        fontSize: 1,
        height: 20,
        px: 2,
    },
    {
        borderRadius: 15,
        fontSize: 12,
        height: 24,
        px: 2,
    },
    {
        borderRadius: 10,
        fontSize: '10px',
        px: 2,
    },
    {
        borderRadius: 30,
        fontSize: '24px',
        height: 36,
        px: 3,
    },
];
export default sizes;
