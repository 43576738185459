export const fontSizes = [
    10, 12, 13, 15, 17, 20, 24, 32, 36, 48, 64, 80,
];
export const fontWeights = {
    bold: 700,
    medium: 500,
    regular: 400,
    semibold: 600,
};
export const lineHeights = {
    base: 1,
    body: 1.6,
    heading: 1.3,
    sub: 1.5,
};
