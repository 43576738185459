import React, { useState } from 'react';
import { Link, Logo, Text } from '@marvelapp/askhub-components';
import { SIDEBAR_WIDTH } from '@marvelapp/theme';
import { Box, Flex, Sidebar as SidebarElement } from '@marvelapp/ui';
import NavLinks from './NavLinks';
import UserBlock from './UserBlock';
export default function Sidebar() {
    // TODO: On mobile, if the nav sidebar is extended then navigating will close the sidebar.
    // This happens because the component is unmounted on navigation and then mounted again with the default false state.
    // The fix would be to pull the Sidbar to a top level, rather than rendering it individually with every Application component
    const [isSidebarExtendedOnMobile, setIsSidebarExtendedOnMobile] = useState(false);
    const toggleSidebarOnMobile = (isExtended) => () => setIsSidebarExtendedOnMobile(isExtended);
    return (React.createElement(SidebarElement, { bg: "slate2", boxShadow: "none", closeTop: 37, height: "100vh", isOpen: isSidebarExtendedOnMobile, onMenuButtonClick: toggleSidebarOnMobile(!isSidebarExtendedOnMobile), sidebarWidth: SIDEBAR_WIDTH },
        React.createElement(Box, { overflow: "auto", width: 1 },
            React.createElement(Box, { position: "relative", pt: [5, 44], px: 3 },
                React.createElement(Flex, { mb: [4, 5], p: 1, px: 3, alignItems: "center" },
                    React.createElement(Link, { to: "/" },
                        React.createElement(Logo, { fontSize: "l" })),
                    React.createElement(Text, { borderColor: "transparent", fontSize: "9px", fontWeight: "medium", height: "18px", letterSpacing: "1px", lineHeight: "18px", marginLeft: "6px", marginTop: "-5px", textTransform: "uppercase" }, "Beta")),
                React.createElement(NavLinks, null))),
        React.createElement(UserBlock, null)));
}
