import { WebinarUpsell } from '../components/WebinarUpsell';
import * as id from '../identifiers';
import * as position from '../positions';
export default function upsells({ userInfo }) {
    const { isFree } = userInfo;
    return {
        [position.DASHBOARD_SIDE_BAR]: [
            {
                identifier: id.WEBINAR,
                component: WebinarUpsell,
                isClosable: true,
                condition: isFree,
                daysUntilReshownAfterClose: 365,
            },
        ],
    };
}
