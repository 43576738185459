import { noop } from 'lodash-es';
import React, { Fragment } from 'react';
import theme, { CLOSE_ICON, SIDEBAR_WIDTH } from '@marvelapp/theme';
import CircleButton from '../CircleButton';
import Icon from '../Icon';
import MenuButton from '../MenuButton';
import Position from '../Position';
import { SidebarElement } from './styles';
function Sidebar({ bg = 'white', boxShadow = 'borders.right', buttonBg, buttonColor, buttonText, children, height, isOpen, maxHeight, onMenuButtonClick, overflow, sidebarWidth, }) {
    const translateWidth = isOpen
        ? `translateX(${sidebarWidth}px)`
        : 'translateX(-1px)';
    const transform = [
        translateWidth,
        translateWidth,
        translateWidth,
        'translateX(0)',
    ];
    const left = [-sidebarWidth, -sidebarWidth, -sidebarWidth, 0];
    const display = ['block', 'block', 'block', 'none'];
    return (React.createElement(Fragment, null,
        React.createElement(SidebarElement, { bg: bg, boxShadow: boxShadow, height: height, left: left, maxHeight: maxHeight, overflow: overflow, top: 0, transform: transform, width: sidebarWidth, zIndex: "sidebar" },
            children,
            React.createElement(Position, { display: display, padding: 12, position: "absolute", right: 0 },
                React.createElement(CircleButton, { onClick: onMenuButtonClick },
                    React.createElement(Icon, { paths: CLOSE_ICON })))),
        React.createElement(MenuButton, { bg: buttonBg, buttonText: buttonText, color: buttonColor, display: display, onClick: onMenuButtonClick })));
}
Sidebar.defaultProps = {
    buttonBg: 'white',
    buttonColor: 'slate',
    buttonText: 'Menu',
    closeTop: 0,
    isOpen: false,
    onMenuButtonClick: noop,
    sidebarWidth: SIDEBAR_WIDTH,
    theme,
};
export default Sidebar;
