import { differenceInMonths } from 'date-fns/esm';
import { get } from 'lodash-es';
import { UserRole, getDaysToDate } from '@marvelapp/core';
import { userSelectors } from '@marvelapp/marvel-3-application';
import { didDowngradeInPastHour, getAssists, seenPlansInPastHour, } from './storage-assist';
const EXPIRY_WINDOW_DAYS = 14;
const SOURCE_STRIPE = 2;
export function getUserInfo(user, subscription, isWhiteboardEnabled) {
    const { pk, createdAt, role, company, testGroup, occupation } = user;
    // Owner (Can touch billing)
    const isOwner = role === UserRole.Owner;
    const ownerUsername = get(company, 'owner.username');
    const ownerEmail = get(company, 'owner.email');
    const paysWithStripe = (subscription === null || subscription === void 0 ? void 0 : subscription.source) === SOURCE_STRIPE;
    const monthsSinceSignup = differenceInMonths(new Date(), new Date(createdAt));
    const remainingTrialDays = get(company, 'billing.trialDaysRemaining');
    const planIsYearly = get(company, 'billing.billingCycle') === 'yearly';
    const planPayment = get(company, 'billing.payment');
    const isAccountLocked = get(company, 'accountLockOverride');
    const nextPaymentDate = get(company, 'billing.nextPaymentDate');
    const daysToNextPayment = getDaysToDate(new Date(nextPaymentDate));
    const nextPaymentWithinExpiryWindow = daysToNextPayment < EXPIRY_WINDOW_DAYS;
    const assists = getAssists();
    const hasSeenPlansInPastHour = seenPlansInPastHour(assists);
    const hasDowngradedInPastHour = didDowngradeInPastHour(assists);
    return {
        pk,
        role,
        testGroup,
        isOwner,
        ownerUsername,
        ownerEmail,
        nextPaymentWithinExpiryWindow,
        isAccountLocked,
        daysToNextPayment,
        planIsYearly,
        occupation,
        planPayment,
        hasSeenPlansInPastHour,
        hasDowngradedInPastHour,
        remainingTrialDays,
        paysWithStripe,
        monthsSinceSignup,
        isWhiteboardEnabled,
        isFree: userSelectors.isFree(user),
        isTeam: userSelectors.isTeam(user),
        isTeamPlus: userSelectors.isTeamPlus(user),
        isPro: userSelectors.isPro(user),
        isEnterprise: userSelectors.isEnterprise(user),
        prototypeProjectCount: userSelectors.prototypeProjectCount(user),
        hasUserTestProjects: userSelectors.hasUserTestProjects(user),
        hasHitPrototypeLimit: userSelectors.hasHitPrototypeLimit(user),
        hasHitUserTestLimit: userSelectors.hasHitUserTestLimit(user),
        hitAnyProjectsLimit: userSelectors.hasHitAnyProjectsLimit(user),
        screensOwnedCount: userSelectors.screensOwnedCount(user),
        teamsOwnedCount: userSelectors.teamsOwnedCount(user),
        foldersOwnedCount: userSelectors.foldersOwnedCount(user),
        prototypeProjectsOwnedCount: userSelectors.prototypeProjectsOwnedCount(user),
        userTestProjectsOwnedCount: userSelectors.userTestProjectsOwnedCount(user),
        hotspotsOwnedCount: userSelectors.hotspotsOwnedCount(user),
    };
}
