import { __rest } from "tslib";
import React from 'react';
import { Svg } from './styles';
/** @param {any} */
function Icon(_a) {
    var { paths, children } = _a, props = __rest(_a, ["paths", "children"]);
    return (React.createElement(Svg, Object.assign({}, props), paths ? paths.map((path) => React.createElement("path", { d: path, key: path })) : children));
}
/** @type {any} */
Icon.defaultProps = {
    fill: 'currentColor',
    height: 24,
    viewBox: '0 0 24 24',
    width: 24,
};
export default Icon;
