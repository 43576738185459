import React from 'react';
import { canBeShownAgain } from '../storage';
import { trackUpsellAppearance, trackUpsellCTA, trackUpsellClosing, } from '../tracking';
function getCurrrentUpsellForPosition({ position, upsells, closed }) {
    const upsell = upsells.find((item) => {
        const { condition, identifier, isClosable } = item;
        // None closable upsells should always be shown as they are important
        if (!isClosable) {
            return condition;
        }
        // Ensures that in the position it only shows 1 banner every 24h
        const canShowAgain = canBeShownAgain({
            identifier,
            closed,
            position,
        });
        return condition && canShowAgain;
    });
    return upsell;
}
function UpsellRenderer({ upsells, stunning, closed, position, onClickClose, userInfo, }) {
    if (!upsells)
        return null;
    const upsell = getCurrrentUpsellForPosition({
        position,
        upsells,
        stunning,
        closed,
    });
    if (!upsell)
        return null;
    // Found a upsell matching conditions to show
    const { identifier, daysUntilReshownAfterClose } = upsell;
    trackUpsellAppearance({ identifier, position });
    const clickedClose = () => {
        trackUpsellClosing({ identifier, position });
        onClickClose({ identifier, position, daysUntilReshownAfterClose });
    };
    const clickedCTA = () => {
        trackUpsellCTA({ identifier, position });
    };
    const UpsellComponent = upsell.component;
    return (React.createElement(UpsellComponent, { clickedCTA: clickedCTA, "data-testid": identifier, identifier: identifier, onClickClose: upsell.isClosable ? clickedClose : null, userInfo: userInfo }));
}
export default UpsellRenderer;
