import React, { useState } from 'react';
import { useString } from '@marvelapp/hooks';
import { Message, Text } from '@marvelapp/ui';
import Button from '../Button';
export default function ConfirmForm({ buttonText, handleFormSubmit }) {
    const [submitErrorMessage, setSubmitErrorMessage, clearSubmitErrorMessage] = useString();
    const [isLoading, setIsLoading] = useState(false);
    function onSubmit() {
        clearSubmitErrorMessage();
        setIsLoading(true);
        // The loading and error state is automatically handled
        // when handleFormSubmit returns a Promise
        Promise.resolve(handleFormSubmit())
            .catch((error) => setSubmitErrorMessage(error.jsxMessage || error.message))
            .finally(() => {
            setIsLoading(false);
        });
    }
    return (React.createElement(React.Fragment, null,
        submitErrorMessage && (React.createElement(Message, { kind: "error", mb: 25 },
            React.createElement(Text, { fontSize: 2 }, submitErrorMessage))),
        React.createElement(Button, { "data-testid": "delete-modal-button", kind: "negative", isLoading: isLoading, onClick: onSubmit, size: "m" }, buttonText)));
}
