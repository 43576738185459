import styled from '@marvelapp/styled';
import Box from '../Box';
export const SidebarElement = styled(Box).attrs({
    transition: 'smooth.fast',
}) `
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: flex-start;
  justify-content: space-between;
  position: fixed;
`;
