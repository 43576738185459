import { __awaiter } from "tslib";
/* GLOBALS __DEV__ */
import { ApolloProvider } from '@apollo/react-hooks';
import { get, merge } from 'lodash-es';
import 'minireset.css/minireset.css';
import pathToRegexp from 'path-to-regexp';
import React from 'react';
// import useDarkMode from 'use-dark-mode';
// react-apollo is being replaced with @apollo/react-XXX
import { ApolloProvider as LegacyApolloProvider } from 'react-apollo';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider as ThemeUIProvider } from 'theme-ui';
import { theme as baseTheme } from '@marvelapp/askhub-theme';
import { UnhandledError } from '@marvelapp/askhub-ui';
import { ErrorBoundary } from '@marvelapp/marvel-3-application';
import { ThemeProvider } from '@marvelapp/styled';
import AppContainer from '../components/AppContainer';
import createGqlClient from '../createGqlClient';
import { routingMap } from '../routingMap';
import { GlobalStyle } from '../theme/globalStyles';
import { getAuth } from '../utils/auth';
// Define all color modes
const modes = ['light', 'dark'];
export const LIGHT_MODE = modes[0];
export const DARK_MODE = modes[1];
// Merge the color mode with the base theme
// to create a new theme object
const getTheme = (mode) => merge({}, baseTheme, {
    colors: get(baseTheme.colors.modes, mode, baseTheme.colors),
    shadows: get(baseTheme.shadows.modes, mode, baseTheme.shadows),
});
// Set up App
function App({ client, accessToken }) {
    // const darkmode = useDarkMode(true);
    const darkmode = { value: false };
    const theme = getTheme(darkmode.value ? modes[1] : modes[0]);
    return (React.createElement(ThemeProvider, { theme: theme },
        React.createElement(ThemeUIProvider, { theme: theme },
            React.createElement(GlobalStyle, { bg: "background", color: "slate11", fontFamily: 0 }),
            React.createElement(BrowserRouter, null,
                React.createElement(ErrorBoundary, { component: UnhandledError },
                    React.createElement(ApolloProvider, { client: client },
                        React.createElement(LegacyApolloProvider, { client: client },
                            React.createElement(AppContainer, { accessToken: accessToken }))))))));
}
export default function createApp() {
    return __awaiter(this, void 0, void 0, function* () {
        const accessToken = getAuth();
        // While we are getting the info we need, we can preload the view
        // that we will be showing
        // eslint-disable-next-line no-restricted-syntax
        for (const route of routingMap) {
            const regex = pathToRegexp(route.path, [], { end: false });
            if (regex.test(document.location.pathname)) {
                route.preload();
                break;
            }
        }
        const mountNode = document.getElementById('root');
        const client = createGqlClient({ accessToken });
        ReactDOM.render(React.createElement(App, { accessToken: accessToken, client: client }), mountNode);
    });
}
createApp();
