import React from 'react';
import { Box, Flex, Link, Text } from '@marvelapp/ui';
import Lock from './Lock';
export default function SecureMessage({ maxWidth = '199px', }) {
    return (React.createElement(Box, { pt: 3 },
        React.createElement(Flex, { alignItems: "flex-start" },
            React.createElement(Lock, { color: "slate", display: "block", mb: "5px", mr: "12px" }),
            React.createElement(Text, { fontSize: 12, maxWidth: maxWidth },
                "This is a secure 128-SSL encrypted connection. Read our",
                ' ',
                React.createElement(Link, { hasUnderline: true, href: "https://ballparkhq.com/terms", target: "blank", rel: "noreferrer noopener", kind: "normal" }, "terms of service"),
                ' ',
                "and",
                ' ',
                React.createElement(Link, { hasUnderline: true, href: "https://ballparkhq.com/privacy", target: "blank", rel: "noreferrer noopener", kind: "normal" }, "privacy"),
                ' ',
                "policies ."))));
}
