import { __rest } from "tslib";
import React, { Fragment, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { NavLink } from '@marvelapp/askhub-components';
import { Webinar } from '@marvelapp/askhub-upsells';
import { useOnClickOutside } from '@marvelapp/hooks';
import { useCurrentUser, usePermissions, userSelectors, } from '@marvelapp/marvel-3-application';
import { Box } from '@marvelapp/ui';
import UpgradeBadge from './UpgradeBadge';
import { getHelpLinks, getMainLinks } from './links';
function NavLinks(_a) {
    var { onLinkClick, location } = _a, props = __rest(_a, ["onLinkClick", "location"]);
    const { pathname } = location;
    const user = useCurrentUser();
    const isFree = userSelectors.isFree(user);
    const permissions = usePermissions();
    const mainLinks = getMainLinks({ permissions });
    const mainLinksCanView = mainLinks.filter((link) => link.canView);
    const helpLinks = getHelpLinks();
    const ref = useRef();
    const [openSubmenu, setOpenSubmenu] = useState(null);
    useOnClickOutside(ref, () => {
        setOpenSubmenu(null);
    });
    return (React.createElement(Box, Object.assign({}, props),
        isFree && (React.createElement(Box, { marginBottom: 4, px: 3 },
            React.createElement(UpgradeBadge, null))),
        mainLinksCanView.map((link) => (React.createElement(Fragment, { key: link.name },
            React.createElement(NavLink, { active: openSubmenu === null &&
                    (link.name === 'Folders'
                        ? pathname.includes('/folder/') ||
                            pathname.includes('/folders/')
                        : pathname.includes(link.to)), kind: pathname.includes(link.to) ? 'licorice' : link.kind, mt: link.mt ? link.mt : 0, onClick: onLinkClick, to: link.to }, link.name),
            pathname.includes(link.to) &&
                link.sublinks
                    .filter((sublink) => sublink.canView)
                    .map((sublink, index, array) => (React.createElement(NavLink, { activeClassName: "active", isSublink: true, key: sublink.name, marginBottom: index === array.length - 1 ? 3 : 1, onClick: onLinkClick, to: sublink.to }, sublink.name)))))),
        helpLinks.map((link) => (React.createElement(NavLink, { key: link.name, kind: "black", to: link.to, mt: link.mt || 0 }, link.name))),
        React.createElement(Webinar, null)));
}
export default withRouter(NavLinks);
