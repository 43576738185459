import { formatDuration as _formatDuration, differenceInCalendarDays, differenceInCalendarMonths, differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds, format, formatDistance, fromUnixTime, getUnixTime, isSameYear, } from 'date-fns';
export function isLessThanNDaysAgo(date, numberOfDays, now = new Date()) {
    return differenceInDays(now, date) <= numberOfDays;
}
export function isLessThanNHoursAgo(date, numberOfHours, now = new Date()) {
    return differenceInHours(now, date) <= numberOfHours;
}
export function isLessThanNMinutesAgo(date, numberOfMinutes, now = new Date()) {
    return differenceInMinutes(now, date) <= numberOfMinutes;
}
export function getDaysToDate(date) {
    return differenceInCalendarDays(date, Date.now());
}
export function formatDate(date, currentDate = new Date()) {
    const isOlderThanOneMonth = differenceInCalendarMonths(currentDate, date) > 1;
    const isWithinSameYear = isSameYear(date, currentDate);
    if (isOlderThanOneMonth) {
        return `${format(date, `dd MMM${!isWithinSameYear ? ' yy' : ''}`)}`;
    }
    const isLessThan30SecondsAgo = differenceInSeconds(currentDate, date) <= 30;
    if (isLessThan30SecondsAgo) {
        return `Just now`;
    }
    const distance = formatDistance(date, currentDate)
        .replace(/about /, '')
        .replace(/^a minute$/, '1 min')
        .replace(/ minutes?$/, ' min')
        .replace(/ hours?$/, 'h')
        .replace(/ days?$/, 'd')
        .replace(/ months?$/, ' mo');
    return `${distance} ago`;
}
export function formatTimestamp(date) {
    const currentDate = new Date();
    const isWithinSameYear = isSameYear(date, currentDate);
    if (isWithinSameYear) {
        return `Updated ${format(date, 'do MMM @ HH:mm a')}`;
    }
    return `Updated ${format(date, 'do MMM yy @ HH:mm a')}`;
}
export function formatDateAndTime(date) {
    const dateString = format(date, 'd MMMM y');
    const timeString = format(date, 'HH:mm');
    return { date: dateString, time: timeString };
}
export function formatDuration(seconds) {
    const days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor((seconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return _formatDuration({ days, hours, minutes, seconds: remainingSeconds }, { format: ['days', 'hours', 'minutes', 'seconds'] });
}
export { getUnixTime, fromUnixTime };
