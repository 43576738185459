import { __rest } from "tslib";
import React, { forwardRef } from 'react';
import { Textarea as MarvelTextarea } from '@marvelapp/ui';
export const kinds = {
    primary: {
        bg: 'background',
        border: '1px solid',
        borderColor: 'slate7',
        boxShadow: 'none',
        color: 'slate12',
        focusBorderColor: 'blue8',
        focusBoxShadow: 'input.focus',
        hoverBorderColor: 'slate8',
        hoverBoxShadow: 'none',
        paddingY: 0,
    },
    disabled: {
        bg: 'slate2',
        border: '1px solid',
        borderColor: 'slate7',
        boxShadow: 'none',
        color: 'slate11',
        focusBorderColor: 'slate7',
        focusBoxShadow: 'none',
        hoverBorderColor: 'slate7',
        hoverBoxShadow: 'none',
    },
    error: {
        bg: 'background',
        border: '1px solid',
        borderColor: 'red6',
        boxShadow: 'none',
        color: 'red12',
        focusBorderColor: 'red8',
        focusBoxShadow: 'input.error',
        hoverBorderColor: 'red8',
        hoverBoxShadow: 'none',
    },
    secondary: {
        bg: 'slate3',
        border: '1px solid',
        borderColor: 'transparent',
        focusBg: 'slate4',
        focusBorderColor: 'blue8',
        focusBoxShadow: 'input.focus',
        hoverBg: 'slate4',
    },
};
export const sizes = {
    s: {
        borderRadius: 'm',
        fontSize: 's',
        lineHeight: '28px',
        minWidth: '30px',
    },
    m: {
        borderRadius: 'm',
        fontSize: 's',
        lineHeight: '38px',
        minWidth: '36px',
    },
    l: {
        borderRadius: 'm',
        fontSize: 's',
        lineHeight: '20px',
        minWidth: '40px',
    },
    xl: {
        borderRadius: 'm',
        fontSize: ['s', 's', 'm'],
        lineHeight: '44px',
        minWidth: '46px',
    },
};
const Textarea = forwardRef((_a, ref) => {
    var { kind = 'primary', size = 'm', disabled } = _a, rest = __rest(_a, ["kind", "size", "disabled"]);
    const kindProps = disabled ? kinds.disabled : kinds[kind];
    return (React.createElement(MarvelTextarea, Object.assign({ ref: ref, disabled: disabled, showBorder: true }, kindProps, sizes[size], rest)));
});
export default Textarea;
