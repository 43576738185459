import React, { createContext, useContext, useMemo } from 'react';
import { createPermissions } from './createPermissions';
const PermissionsContext = createContext(null);
export const PermissionsProvider = (props) => {
    const permissions = useMemo(() => createPermissions(props.user, props.subscription), [props.user, props.subscription]);
    return (React.createElement(PermissionsContext.Provider, { value: permissions }, props.children));
};
export function usePermissions() {
    return useContext(PermissionsContext);
}
