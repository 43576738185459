import { __rest } from "tslib";
import PropTypes from 'prop-types';
import React from 'react';
import { CLOSE_ICON } from '@marvelapp/theme';
import Icon from '../Icon';
import PinTR from '../PinTR';
import Text from '../Text';
import { ImageHolder, TinyUpsellerWrapper } from './styles';
function TinyUpseller(_a) {
    var { image, onClickClose, title, callToAction } = _a, rest = __rest(_a, ["image", "onClickClose", "title", "callToAction"]);
    return (React.createElement(TinyUpsellerWrapper, Object.assign({ alignItems: "center", borderRadius: 2, boxShadow: "button.ghost", flexDirection: "column", justifyContent: "center", position: "relative", px: 3, py: 4, width: 156 }, rest),
        React.createElement(ImageHolder, null, image),
        React.createElement(Text, { color: "licorice", fontSize: 2, fontWeight: 500, pb: 3, pt: 3, textAlign: "center" }, title),
        callToAction,
        onClickClose && (React.createElement(PinTR, { cursor: "pointer", "data-testid": "upsell-close", onClick: onClickClose, padding: 2 },
            React.createElement(Icon, { color: "silver", display: "block", hoverColor: "black", paths: CLOSE_ICON })))));
}
TinyUpseller.propTypes = {
    image: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    callToAction: PropTypes.node.isRequired,
    onClickClose: PropTypes.func,
};
export default TinyUpseller;
