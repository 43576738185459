// Documentation
// Workspace roles: https://marvelapp.quip.com/Ja9zAloQPGLF
// Permissions: https://marvelapp.quip.com/UyEPA806lomN
export var UserRole;
(function (UserRole) {
    UserRole["Owner"] = "OWNER";
    UserRole["Admin"] = "ADMIN";
    UserRole["Editor"] = "EDITOR";
    UserRole["InternalReviewer"] = "INTERNAL_REVIEWER";
    UserRole["ExternalEditor"] = "EXTERNAL_EDITOR";
    UserRole["ExternalReviewer"] = "EXTERNAL_REVIEWER";
})(UserRole || (UserRole = {}));
export var UserRoleType;
(function (UserRoleType) {
    UserRoleType["Member"] = "MEMBER";
    UserRoleType["Guest"] = "GUEST";
})(UserRoleType || (UserRoleType = {}));
export const userRoleTypes = {
    [UserRole.Owner]: UserRoleType.Member,
    [UserRole.Admin]: UserRoleType.Member,
    [UserRole.Editor]: UserRoleType.Member,
    [UserRole.InternalReviewer]: UserRoleType.Member,
    [UserRole.ExternalReviewer]: UserRoleType.Guest,
    [UserRole.ExternalEditor]: UserRoleType.Guest,
};
export const roleDisplayLabels = {
    [UserRole.Owner]: 'Owner',
    [UserRole.Admin]: 'Admin',
    [UserRole.Editor]: 'Member (Can edit)',
    [UserRole.InternalReviewer]: 'Member (View only)',
    [UserRole.ExternalReviewer]: 'Contributor (View only)',
    [UserRole.ExternalEditor]: 'Contributor (Can edit)',
};
export const ballparkRoleDisplayLabels = {
    [UserRole.Owner]: 'Owner',
    [UserRole.Admin]: 'Admin',
    [UserRole.Editor]: 'Member (Can edit)',
    [UserRole.InternalReviewer]: 'Member (View only)',
    [UserRole.ExternalReviewer]: 'Guest (View only)',
    [UserRole.ExternalEditor]: 'Guest (Can edit)',
};
export const getRoleDisplayLabel = (role) => roleDisplayLabels[role];
export function isGuest(user) {
    return userRoleTypes[user.role] === UserRoleType.Guest;
}
export function isGuestEditor(user) {
    return user.role === UserRole.ExternalEditor;
}
export function isMember(user) {
    return userRoleTypes[user.role] === UserRoleType.Member;
}
export function isReviewer(role) {
    return (role === UserRole.InternalReviewer || role === UserRole.ExternalReviewer);
}
// Used by legacy role APIs, where we are required to
// send the numerical code for a specific role.
// This is a 2-way mapping to map from codes to UserRole
export const legacyUserRoleCodes = {
    [UserRole.Owner]: 60,
    '60': UserRole.Owner,
    [UserRole.Admin]: 50,
    '50': UserRole.Admin,
    [UserRole.Editor]: 30,
    '30': UserRole.Editor,
    [UserRole.InternalReviewer]: 20,
    '20': UserRole.InternalReviewer,
    [UserRole.ExternalEditor]: 15,
    '15': UserRole.ExternalEditor,
    [UserRole.ExternalReviewer]: 10,
    '10': UserRole.ExternalReviewer,
};
// A 2-way mapping from legacy user roles returned by old rest API
// to new enum UserRole. Please don't use it for labels.
export const legacyUserRoles = {
    Owner: UserRole.Owner,
    [UserRole.Owner]: 'Owner',
    Admin: UserRole.Admin,
    [UserRole.Admin]: 'Admin',
    Editor: UserRole.Editor,
    [UserRole.Editor]: 'Editor',
    'Internal Reviewer': UserRole.InternalReviewer,
    [UserRole.InternalReviewer]: 'Internal Reviewer',
    'External Reviewer': UserRole.ExternalReviewer,
    [UserRole.ExternalReviewer]: 'External Reviewer',
    'External Editor': UserRole.ExternalEditor,
    [UserRole.ExternalEditor]: 'External Editor',
};
// TODO: Kill this Role. ANONYMOUS is not a real user role, and is not supported by the backend.
// We use this role in a few places to refer to users who are not authenticated, but still have
// access to public parts of the application (like folders). A better solution would be to have
// an isAuthenticated on the user object.
export const ANONYMOUS = 'ANONYMOUS';
