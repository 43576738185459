import { heapTrackEvent } from '@marvelapp/core';
export function trackUpsellAppearance({ identifier, position }) {
    heapTrackEvent('Upsell Appeared', { identifier, position });
}
export function trackUpsellClosing({ identifier, position }) {
    heapTrackEvent('Upsell Close Clicked', { identifier, position });
}
export function trackUpsellCTA({ identifier, position }) {
    heapTrackEvent('Upsell CTA Clicked', { identifier, position });
}
