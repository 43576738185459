// eslint-disable-next-line import/no-cycle
import { devOverrides } from './devOverrides';
import { Tool } from './tools';
export var ABTest;
(function (ABTest) {
    ABTest["WHITEBOARD_UI"] = "WHITEBOARD_UI";
    ABTest["FIGMA_USERTESTS"] = "FIGMA_USERTESTS";
})(ABTest || (ABTest = {}));
const abTestTriggers = {
    [ABTest.WHITEBOARD_UI]: () => true,
    [ABTest.FIGMA_USERTESTS]: (user) => {
        // Whiteboard UI is enabled for 100% of paying users
        return user.tool === Tool.Figma;
    },
};
export const isInABTestVariant = (testKey, user) => {
    const overrides = devOverrides.abTests.get();
    const forcedOverride = overrides[testKey];
    if (forcedOverride) {
        return forcedOverride === 'variant';
    }
    return abTestTriggers[testKey](user) === true;
};
