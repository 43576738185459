import { createMemoryHistory } from 'history';
import { find } from 'lodash-es';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { matchPath, useHistory } from 'react-router-dom';
import { ARROW_LEFT_ICON } from '@marvelapp/theme';
import { Icon, IconButton } from '@marvelapp/ui';
import Link from '../Link';
import { BackElement } from './styles';
export const BackLinkContext = createContext();
const PATH_TO_ENTRY_POINTS = {
    '/project/:id': ['/projects/:filter', '/folder/:id'],
    '/folder/:id': ['/folders/:filter'],
    '/user-test/:id': ['/project/:id', '/projects/:filter', '/folder/:id'],
};
export default function ProjectBarBackLink({ defaultBackLink }) {
    const { backLink } = useContext(BackLinkContext);
    return (React.createElement(BackElement, { marginRight: 3 },
        React.createElement(IconButton, { as: Link, color: "slate", "data-testid": "project-bar-back-link", hoverColor: "licorice", px: 0, to: backLink || defaultBackLink, width: 26 },
            React.createElement(Icon, { paths: ARROW_LEFT_ICON }))));
}
function getBackLink(shadowHistory) {
    if (!(shadowHistory.entries && shadowHistory.entries.length > 1)) {
        return {};
    }
    const entryPoints = find(PATH_TO_ENTRY_POINTS, (_, path) => matchPath(shadowHistory.location.pathname, { path, exact: true }));
    if (!entryPoints) {
        return {};
    }
    let i = shadowHistory.index - 1;
    while (i >= 0 &&
        // eslint-disable-next-line no-loop-func
        !entryPoints.some((entryPoint) => matchPath(shadowHistory.entries[i].pathname, {
            path: entryPoint,
            exact: true,
        }))) {
        i -= 1;
    }
    if (i === -1) {
        return {};
    }
    return { backLink: shadowHistory.entries[i] };
}
export function BackLinkProvider({ children }) {
    const history = useHistory();
    const [backLink, setBackLink] = useState({
        backLink: '/',
    });
    useEffect(() => {
        if (!history)
            return;
        // Replay navigation to memory history so we can inspect entries
        const shadowHistory = createMemoryHistory({
            initialEntries: [history.location],
        });
        const unlisten = history.listen((newLocation, action) => {
            if (action === 'PUSH') {
                shadowHistory.push(newLocation);
            }
            else if (action === 'REPLACE') {
                shadowHistory.replace(newLocation);
            }
            else {
                shadowHistory.goBack();
            }
        });
        shadowHistory.listen(() => {
            setBackLink(getBackLink(shadowHistory));
        });
        return unlisten;
    }, [history]);
    return (React.createElement(BackLinkContext.Provider, { value: backLink }, children));
}
