import { border, color, flexbox, grid, layout, opacity, position, shadow, space, typography, } from 'styled-system';
import styled, { css } from '@marvelapp/styled';
import theme, { breakpoints } from '@marvelapp/theme';
import { borderBottomLeftRadius, borderBottomRightRadius, borderTopLeftRadius, borderTopRightRadius, cursor, fill, filter, focusBg, focusBorderColor, focusBoxShadow, focusColor, hoverBg, hoverBorderColor, hoverBoxShadow, hoverColor, hoverFill, hoverOpacity, hoverTextDecorationColor, objectFit, overflow, overflowX, overflowY, pointerEvents, textDecorationColor, textDecorationLine, textDecorationThickness, textTransform, textUnderlineOffset, transform, transformOrigin, transition, userSelect, whiteSpace, wordBreak, wordWrap, } from './customProperties';
export const boxStyles = css `
  ${border};
  ${borderBottomLeftRadius};
  ${borderBottomRightRadius};
  ${borderTopLeftRadius};
  ${borderTopRightRadius};
  ${color};
  ${cursor};
  ${fill};
  ${filter};
  ${flexbox};
  ${grid};
  ${layout};
  ${objectFit};
  ${opacity};
  ${overflow};
  ${overflowX};
  ${overflowY};
  ${pointerEvents};
  ${position};
  ${shadow};
  ${space};
  ${textDecorationColor};
  ${textDecorationLine};
  ${textDecorationThickness};
  ${textTransform};
  ${textUnderlineOffset};
  ${transform};
  ${transformOrigin};
  ${transition};
  ${typography};
  ${userSelect};
  ${whiteSpace};
  ${wordBreak};
  ${wordWrap};

  box-sizing: border-box;

  @media (min-width: ${breakpoints[0]}) {
    &:hover {
      ${hoverBg};
      ${hoverBorderColor};
      ${hoverBoxShadow};
      ${hoverColor};
      ${hoverFill};
      ${hoverOpacity};
      ${hoverTextDecorationColor};
    }

    &:focus {
      ${focusBg};
      ${focusBorderColor};
      ${focusBoxShadow};
      ${focusColor};
    }
  }

  ${(props) => props.truncated &&
    css `
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `};

  ${(props) => props.truncatedTwo &&
    css `
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    `};
`;
/** @type {any} */
const Box = styled.div `
  ${boxStyles};
`;
Box.defaultProps = {
    theme,
};
export default Box;
