import { ApolloClient, InMemoryCache } from '@apollo/client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { has } from 'lodash-es';
import { fetchJSON, getCookie } from '@marvelapp/core';
import config from './config';
import { clearAuth, setAuth } from './utils/auth';
const CSRF_COOKIE_KEY = 'csrftoken';
const RETRIEVE_OAUTH_TOKEN_URI = '/api/oauth/retrieve/';
const CSRF_TOKEN = getCookie(CSRF_COOKIE_KEY);
export default function createGqlClient({ accessToken: initialAccessToken }) {
    let accessToken = initialAccessToken;
    let refreshingPromise = null;
    const authorizationMiddleware = new ApolloLink((operation, forward) => {
        const context = operation.getContext();
        // Don't ovveride the authorization header if the access token is undefined
        // or if the query/mutation has a custom authorization defined in the context
        if (accessToken && !has(context, 'headers.Authorization')) {
            operation.setContext({
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        }
        return forward(operation);
    });
    function fetchAndRefreshToken(uri, options) {
        return fetch(uri, options).then((response) => {
            if (response.status !== 401) {
                return response;
            }
            // We cannot refresh the token on local development or Netlify previews
            // because the CSRF token is only injected in the browser's cookies by Django
            if (!CSRF_TOKEN) {
                // eslint-disable-next-line no-console
                console.warn('Unauthorized response, cannot refresh the OAuth token without a CSRF token');
                clearAuthAndRedirectToLoginPage();
            }
            // Refresh the OAuth token
            // This reference to the refreshingPromise let us check if we're already
            // refreshing the OAuth token
            if (!refreshingPromise) {
                refreshingPromise = fetchJSON(RETRIEVE_OAUTH_TOKEN_URI, {
                    method: 'POST',
                    headers: {
                        'X-CSRFToken': CSRF_TOKEN,
                    },
                }).catch(() => {
                    // Something went wrong when retrieving the new token
                    // There isn't much that we can do at this point 🤷🏻‍
                    clearAuthAndRedirectToLoginPage();
                });
            }
            return refreshingPromise.then(({ token: newAccessToken, expires }) => {
                // Now that the refreshing promise has been executed, set it to null
                // and update the access token
                refreshingPromise = null;
                accessToken = newAccessToken;
                // TODO: /api/oauth/retrieve/ should respond with the current scopes
                // and expiry date in UNIX timestamp
                setAuth(newAccessToken, Date.parse(expires), config.pie.scopes);
                // Invoke fetch with the new access token.
                // If the initial request had errors, this fetch that is returned below
                // is the final result
                const newOptions = Object.assign(Object.assign({}, options), { headers: Object.assign(Object.assign({}, options.headers), { Authorization: `Bearer ${accessToken}` }) });
                return fetch(uri, newOptions);
            });
        });
    }
    return new ApolloClient({
        link: ApolloLink.from([
            onError(({ graphQLErrors }) => {
                if (graphQLErrors) {
                    graphQLErrors.map(({ message, locations, path }) => 
                    // eslint-disable-next-line no-console
                    console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`));
                }
            }),
            authorizationMiddleware,
            new HttpLink({
                fetch: fetchAndRefreshToken,
                uri: `${config.pie.host}/graphql`,
                credentials: 'same-origin',
            }),
        ]),
        cache: new InMemoryCache({
            // The default implementation of dataIdFromObject looks for ID fields
            // named `id` or `_id`, so we override it to use `pk` instead
            dataIdFromObject(result) {
                if (result.__typename && result.pk) {
                    return `${result.__typename}:${result.pk}`;
                }
                if (result.__typename === 'BillingNode') {
                    return result.subscriptionPk;
                }
                if (result.__typename === 'Seats') {
                    return result.__typename;
                }
                return null;
            },
            typePolicies: {
                PrivateUserNode: {
                    fields: {
                        properties: {
                            merge: true,
                        },
                    },
                },
                CompanyNode: {
                    fields: {
                        settings: {
                            merge: true,
                        },
                    },
                },
            },
            possibleTypes: {
                AnswerTypeUnion: [
                    'AnswerPlainText',
                    'AnswerEmail',
                    'AnswerBoolean',
                    'AnswerDateTime',
                    'AnswerRating',
                    'AnswerMultiPreference',
                    'AnswerInteger',
                ],
            },
        }),
        defaultOptions: {
            watchQuery: {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'cache-and-network',
                // See https://github.com/marvelapp/mkiii/pull/3242 for the convoluted explanation
                // for why we need this
                nextFetchPolicy: 'cache-first',
            },
        },
    });
}
function clearAuthAndRedirectToLoginPage() {
    clearAuth();
    window.location = '/login';
}
