import { useMutation } from '@apollo/react-hooks';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback, useEffect, useState } from 'react';
import FocusLock from 'react-focus-lock';
import { matchPath, useLocation } from 'react-router-dom';
import { heapTrackEvent } from '@marvelapp/core';
import { useCurrentUser } from '@marvelapp/marvel-3-application';
import styled from '@marvelapp/styled';
import { Box, Button, Text } from '@marvelapp/ui';
import ContentBox from './ContentBox';
import Mask from './Mask';
import { INTERVAL_MS } from './constants';
import data from './data';
import { useEventListener } from './hooks/useEventListener';
import { useInterval } from './hooks/useInterval';
import { UPDATE_USER_PROPERTIES } from './queries/mutations';
export default function MarvelTour() {
    // 💆‍♀️ State
    const location = useLocation();
    const user = useCurrentUser();
    const [foundElementRect, setFoundElementRect] = useState(null);
    const [updateUserProperties] = useMutation(UPDATE_USER_PROPERTIES);
    // 🧚 Helpers
    const flowsWithMatchingPaths = data.filter((flow) => {
        return flow.steps.find((step) => isValidPath(location, step));
    });
    const flows = flowsWithMatchingPaths.map((f) => {
        const cs = user.properties[f.id];
        return {
            id: f.id,
            step: f.steps[cs],
            totalSteps: f.steps.length,
        };
    });
    const matchingFlow = flows[0];
    const id = matchingFlow === null || matchingFlow === void 0 ? void 0 : matchingFlow.id;
    const totalSteps = matchingFlow === null || matchingFlow === void 0 ? void 0 : matchingFlow.totalSteps;
    const step = matchingFlow === null || matchingFlow === void 0 ? void 0 : matchingFlow.step;
    const currentStep = user.properties[id];
    const showTour = step && foundElementRect;
    // 🧰 Actions
    const completeCurrentStep = useCallback(() => {
        heapTrackEvent('Marvel Tour Completed', {
            identifier: id,
            step: currentStep,
        });
        setFoundElementRect(null);
        updateUserProperties({
            variables: { [id]: currentStep + 1 },
            optimisticResponse: {
                updateUserProperties: {
                    ok: true,
                    user: {
                        pk: user.pk,
                        properties: Object.assign(Object.assign({}, user.properties), { [id]: currentStep + 1 }),
                        __typename: 'UserNode',
                    },
                    __typename: 'updateUserProperties',
                },
            },
        });
    }, [id, currentStep, updateUserProperties, user.pk, user.properties]);
    const checkIfCompletedElementAppeared = useCallback(() => {
        if (!(step === null || step === void 0 ? void 0 : step.completeOnAppearance)) {
            return null;
        }
        const elementsAppeared = queryElementsVisible(step.completeOnAppearance, step.completeOnAppearanceExpected || 1);
        if (elementsAppeared && isValidPath(location, step)) {
            completeCurrentStep();
        }
    }, [completeCurrentStep, location, step]);
    const checkForTrigger = useCallback(() => {
        if (step) {
            const element = document.querySelector(step.trigger);
            if (element && htmlElementIsVisible(element)) {
                setFoundElementRect(element.getBoundingClientRect());
            }
            else {
                setFoundElementRect(null);
            }
        }
    }, [step]);
    const onClick = useCallback((e) => {
        if ((step === null || step === void 0 ? void 0 : step.complete) &&
            e.target.matches(`${step.complete}, ${step.complete} *`)) {
            completeCurrentStep();
        }
    }, [completeCurrentStep, step]);
    const skip = useCallback(() => {
        heapTrackEvent('Marvel Tour Skipped', {
            identifier: id,
            step: currentStep,
        });
        updateUserProperties({
            variables: { [id]: totalSteps },
        });
    }, [currentStep, id, totalSteps, updateUserProperties]);
    // ⏰ Checks constantly for changes
    const inspectPage = useCallback(() => {
        checkForTrigger();
        checkIfCompletedElementAppeared();
    }, [checkForTrigger, checkIfCompletedElementAppeared]);
    // 🪝 Hooks
    useEventListener('click', onClick);
    useInterval(inspectPage, step ? INTERVAL_MS : null);
    // 📈 Analytics
    useEffect(() => {
        if (showTour && step) {
            heapTrackEvent('Marvel Tour Appeared', {
                identifier: step.id,
                step: currentStep,
            });
        }
    }, [currentStep, showTour, step]);
    const animation = {
        animate: {
            opacity: 1,
        },
        initial: {
            opacity: 0,
        },
        transition: {
            duration: 0.6,
        },
    };
    const zIndex = 'modal';
    return (React.createElement(AnimatePresence, null, showTour && (React.createElement(Animatable, { animate: animation.animate, initial: animation.initial, key: "tour", transition: animation.transition },
        React.createElement(Box, null,
            React.createElement(Mask, { disableInteraction: step.disableInteractionMask, padding: 10, rounded: 10, targetHeight: foundElementRect.height, targetLeft: foundElementRect.left, targetTop: foundElementRect.top, targetWidth: foundElementRect.width, windowHeight: window.innerHeight, windowWidth: window.innerWidth, zIndex: zIndex }),
            React.createElement(FocusLock, null,
                React.createElement(ContentBox, { currentStep: currentStep + 1, position: step.position, rect: foundElementRect, totalSteps: totalSteps, zIndex: zIndex },
                    React.createElement(Text, { fontSize: 2 }, step.content),
                    step.skippable && (React.createElement(Button, { kind: "ghost", mt: 3, onClick: skip, size: 0 }, "Skip tutorial")))))))));
}
function queryElementsVisible(query, total) {
    const completedElements = Array.from(document.querySelectorAll(query));
    const completedElementsVisible = completedElements.filter((e) => htmlElementIsVisible(e));
    return completedElementsVisible.length >= total;
}
function htmlElementIsVisible(el) {
    const style = getComputedStyle(el);
    const rect = el.getBoundingClientRect();
    const isZeroSize = (rect === null || rect === void 0 ? void 0 : rect.width) === 0 || (rect === null || rect === void 0 ? void 0 : rect.height) === 0;
    const isHidden = style.visibility === 'hidden' || style.display === 'none' || isZeroSize;
    return !isHidden;
}
function isValidPath(location, stepData) {
    const path = stepData === null || stepData === void 0 ? void 0 : stepData.path;
    if (!path) {
        return false;
    }
    return matchPath(location.pathname, {
        path,
        exact: true,
    });
}
const Animatable = styled(motion.div) `
  z-index: ${(props) => props.theme.zIndices.modal};
  position: fixed;
`;
