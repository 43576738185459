import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React from 'react';
import { Toggle as InputToggle, Label, Text, } from '@marvelapp/askhub-components';
import styled, { keyframes } from '@marvelapp/styled';
import { Box, boxStyles } from '@marvelapp/ui';
const slideUp = keyframes `
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;
const slideDown = keyframes `
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;
const Content = styled(DropdownMenu.Content) `
  border-radius: ${(props) => props.borderRadius
    ? props.theme.radii[props.borderRadius]
    : props.theme.raddi.m}px;
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: ${(props) => props.theme.shadows.popover.default};

  /* TODO: adding the transition here breaks the click outside handler for 
     some reson – investigate a possible fix */

  /* animation-duration: 350ms;
  animation-timing-function: ${(props) => props.theme.easings.smooth.string};

  &[data-side='top'] {
    animation-name: ${slideUp};
  }
  &[data-side='bottom'] {
    animation-name: ${slideDown};
  } */
`;
Content.defaultProps = {
    borderRadius: 'm',
    sideOffset: 8,
};
// Dropdown.Item
const Item = styled(DropdownMenu.Item).attrs({
    bg: 'background',
    color: 'slate11',
    display: 'block',
    fontSize: 's',
    height: '32px',
    hoverBg: 'slate3',
    lineHeight: '32px',
    pl: 3,
    pr: 3,
    pt: '1px',
}) `
  ${boxStyles};
  cursor: pointer;
  outline: none; /* TODO: sort out outline or some other accessibility feature */
`;
Item.defaultProps = {
    hoverColor: 'slate12',
};
// Dropdown.Trigger
const Trigger = styled(DropdownMenu.Trigger) `
  ${boxStyles};
  outline: none; /* TODO: sort out outline or some other accessibility feature */
`;
Trigger.defaultProps = {
    bg: 'transparent',
    border: 'none',
    boxShadow: 'none',
    padding: 0,
};
// Dropdown.Inner
const Inner = styled(Box).attrs({
    padding: 'm',
}) `
  width: 320px;
`;
const Inline = styled(Box) `
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Icon = styled(Inline) `
  justify-content: flex-start;
  svg {
    margin-right: ${(props) => props.theme.space.xs}px;
  }
`;
function LabelItem({ children, description, extraElement, hasBorder, icon, id, title, }) {
    return (React.createElement(Row, { hasBorder: hasBorder },
        React.createElement(Inline, { width: 1 },
            React.createElement(Icon, null,
                icon,
                React.createElement(Label, { paddingTop: "2px", fontSize: 14, htmlFor: id, size: "m" }, title)),
            extraElement),
        description && (React.createElement(Text, { marginTop: "xxs", size: "s", paddingRight: "34px" }, description)),
        children && React.createElement(Box, { marginTop: "s" }, children)));
}
function Toggle({ checked, description, disabled, hasBorder, icon, id, onChange, testId, title, }) {
    return (React.createElement(LabelItem, { description: description, hasBorder: hasBorder, extraElement: React.createElement(InputToggle, { checked: checked, disabled: disabled, id: id, onChange: onChange, size: 40, testId: testId }), icon: icon, id: id, title: title }));
}
// Dropdown.Row
const Row = styled(Box).attrs((props) => ({
    borderBottom: props.hasBorder ? '1px solid' : 'none',
    borderColor: 'slate6',
    marginBottom: props.hasBorder ? 'm' : 0,
    paddingBottom: props.hasBorder ? 'm' : 0,
})) `
  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;
  }
`;
const { Root } = DropdownMenu;
export { Root, Trigger, Content, Item, LabelItem as Label, Inner, Toggle };
