import { __rest } from "tslib";
import React, { forwardRef } from 'react';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import Input from './Input';
import Label, { LabelContainer } from './Label';
import Text from './Text';
const InputWithLabel = forwardRef((props, ref) => {
    const { error, id, inputSize = 'm', isOptional, kind, label, labelSize = 's', labelWidth, lineHeight, onChange, placeholder, subheading, value } = props, rest = __rest(props, ["error", "id", "inputSize", "isOptional", "kind", "label", "labelSize", "labelWidth", "lineHeight", "onChange", "placeholder", "subheading", "value"]);
    return (React.createElement(React.Fragment, null,
        React.createElement(LabelContainer, { marginBottom: 12 },
            React.createElement(Box, { display: "flex", flexDirection: "column" },
                React.createElement(Label, { display: "inline-block", htmlFor: id, isOptional: isOptional, size: labelSize, width: labelWidth }, label),
                subheading && (React.createElement(Text, { color: "slate12", mt: "xs", size: "s" }, subheading))),
            error && (React.createElement(Error, { "data-testid": "input-error", fontSize: labelSize }, error))),
        React.createElement(Input, Object.assign({ id: id, kind: kind, lineHeight: lineHeight, onChange: onChange, placeholder: placeholder, ref: ref, size: inputSize, value: value, width: 1 }, rest))));
});
export default InputWithLabel;
const Error = styled(Box).attrs({
    lineHeight: 'base',
    color: 'red11',
}) ``;
