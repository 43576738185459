import { HELP } from '@marvelapp/ballpark-help';
const getUserLink = () => '/user/account/personal';
function getMainLinks({ permissions }) {
    return [
        {
            name: 'Projects',
            to: '/projects',
            sublinks: [],
            kind: 'slate',
            canView: true,
        },
        // TODO: enable folders link when we add Folders back in
        // {
        //   name: 'Folders',
        //   to: '/folders',
        //   sublinks: [],
        //   kind: 'slate',
        //   canView: permissions.canViewFolders(),
        // },
        {
            name: 'Archive',
            to: '/archive',
            sublinks: [],
            kind: 'slate',
            canView: true,
        },
        {
            name: 'Team',
            to: '/company/people',
            kind: 'slate',
            sublinks: [],
            // sublinks: [
            //   {
            //     name: 'People',
            //     kind: 'slate',
            //     to: '/company/people',
            //     canView: true,
            //   },
            //   {
            //     name: 'Groups',
            //     to: '/company/groups',
            //     canView: permissions.canEditGroups(),
            //   },
            //   {
            //     name: 'Settings',
            //     kind: 'slate',
            //     to: '/company/settings',
            //     canView: permissions.canEditTeamSettings(),
            //   },
            //   {
            //     name: 'SSO',
            //     kind: 'slate',
            //     to: '/company/sso',
            //     canView: !isEnterprise,
            //   },
            // ],
            canView: permissions.canViewPeople(),
        },
        {
            name: 'Team',
            to: '/company/guest',
            kind: 'slate',
            canView: !permissions.canViewPeople(),
            sublinks: [],
        },
        {
            name: 'Templates',
            to: '/templates',
            kind: 'slate',
            canView: true,
            sublinks: [],
        },
    ];
}
const getHelpLinks = () => {
    const helpLinks = [
        {
            name: 'Help',
            kind: 'slate',
            to: HELP,
            mt: 34,
        },
        {
            name: 'Roadmap',
            to: 'https://portal.productboard.com/ballpark/1-ballpark',
            canView: true,
            kind: 'slate',
            sublinks: [],
        },
    ];
    return helpLinks;
};
const getUserBlockLinks = () => {
    return [
        {
            name: 'My account',
            to: getUserLink(),
        },
    ];
};
export { getUserLink, getHelpLinks, getMainLinks, getUserBlockLinks };
