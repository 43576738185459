const { useEffect } = require('react');
export function useInterval(cb, interval = 1000) {
    useEffect(() => {
        if (interval === null)
            return;
        const id = setInterval(() => {
            cb();
        }, interval);
        return () => {
            clearInterval(id);
        };
    }, [cb, interval]);
}
