import gql from 'graphql-tag';
export const UPDATE_USER_PROPERTIES = gql `
  mutation UPDATE_USER_PROPERTIES(
    $prototypingOnboardingFlow: Int
    $designToolOnboardingFlow: Int
  ) {
    updateUserProperties(
      input: {
        prototypingOnboardingFlow: $prototypingOnboardingFlow
        designToolOnboardingFlow: $designToolOnboardingFlow
      }
    ) {
      ok
      user {
        pk
        properties {
          prototypingOnboardingFlow
          designToolOnboardingFlow
        }
      }
    }
  }
`;
