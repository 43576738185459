import React from 'react';
import { BaseError, Link } from '@marvelapp/askhub-components';
export default function UnhandledError() {
    const subheading = (React.createElement(React.Fragment, null,
        "It looks like there was an unexpected error. It pains us to ask, but please can you try refreshing the page? If the problem persists,",
        ' ',
        React.createElement(Link, { href: "mailto:help@ballparkhq.com", kind: "ballpark", tag: "a", target: "_blank" }, "get in touch"),
        "."));
    return React.createElement(BaseError, { heading: "Something went wrong", subheading: subheading });
}
