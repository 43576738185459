import React, { createContext, useContext } from 'react';
import { isInABTestVariant } from '@marvelapp/core';
import { PermissionsProvider } from './PermissionsProvider';
const AuthContext = createContext();
const BallparkContext = createContext();
const ConfigContext = createContext();
const FeatureFlagsContext = createContext();
const LastLocationContext = createContext();
const LogoutContext = createContext();
const SidebarContext = createContext();
const PusherContext = createContext();
const RouteConfigContext = createContext({
    loginUri: '/login',
});
const SubscriptionContext = createContext();
const UserContext = createContext();
const AppContainerContext = createContext();
function Marvel3Provider({ accessToken, children, config, featureFlags, isBallpark = false, lastLocation, logout, pusher, refetchUserData, setSubscription, sidebar, subscription, transactionalKey, user, }) {
    return (React.createElement(AuthContext.Provider, { value: accessToken },
        React.createElement(FeatureFlagsContext.Provider, { value: featureFlags },
            React.createElement(ConfigContext.Provider, { value: config },
                React.createElement(LastLocationContext.Provider, { value: lastLocation },
                    React.createElement(SidebarContext.Provider, { value: sidebar },
                        React.createElement(PermissionsProvider, { subscription: subscription, user: user },
                            React.createElement(PusherContext.Provider, { value: { pusher, transactionalKey } },
                                React.createElement(UserContext.Provider, { value: user },
                                    React.createElement(LogoutContext.Provider, { value: logout },
                                        React.createElement(SubscriptionContext.Provider, { value: [subscription, setSubscription] },
                                            React.createElement(BallparkContext.Provider, { value: isBallpark },
                                                React.createElement(AppContainerContext.Provider, { value: refetchUserData }, children)))))))))))));
}
const FeatureFlagsConsumer = FeatureFlagsContext.Consumer;
const ConfigConsumer = ConfigContext.Consumer;
const useAuth = () => useContext(AuthContext);
const useBallparkContext = () => useContext(BallparkContext);
const useConfig = () => useContext(ConfigContext);
const useCurrentSubscription = () => useContext(SubscriptionContext);
const useCurrentUser = () => useContext(UserContext);
const useFeatureFlag = (key) => useContext(FeatureFlagsContext)[key];
const useLastLocation = () => useContext(LastLocationContext);
const useLogout = () => useContext(LogoutContext);
const usePusher = () => useContext(PusherContext);
const useRouteConfig = () => useContext(RouteConfigContext);
const useSidebar = () => useContext(SidebarContext);
const useABTest = (testKey) => {
    const currentUser = useCurrentUser();
    return isInABTestVariant(testKey, currentUser);
};
const useRefetchUserData = () => useContext(AppContainerContext);
export { Marvel3Provider, 
// contexts
FeatureFlagsContext, RouteConfigContext, 
// consumers for old components
FeatureFlagsConsumer, ConfigConsumer, 
// hooks
useAuth, useBallparkContext, useConfig, useCurrentSubscription, useCurrentUser, useABTest, useFeatureFlag, useLastLocation, useLogout, usePusher, useRouteConfig, useSidebar, useRefetchUserData, };
