import { __rest } from "tslib";
import React from 'react';
import { CLOSE_ICON } from '@marvelapp/theme';
import CircleButton from '../CircleButton';
import Icon from '../Icon';
import { iconSizes, sizes } from './sizes';
function CloseButton(_a) {
    var { size } = _a, rest = __rest(_a, ["size"]);
    return (React.createElement(CircleButton, Object.assign({ height: 40, kind: "ghost" }, sizes[size], rest),
        React.createElement(Icon, Object.assign({ display: "block", fill: "inherit", paths: CLOSE_ICON }, iconSizes[size]))));
}
CloseButton.defaultProps = {
    size: 2,
    kind: 'ghost',
};
export default CloseButton;
