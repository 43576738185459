import { amber, amberDark, blue, blueDark, crimson, crimsonDark, cyan, cyanDark, grass, grassDark, green, greenDark, indigo, indigoDark, lime, limeDark, mint, mintDark, olive, orange, orangeDark, pink, pinkDark, plum, plumDark, purple, purpleDark, red, redDark, sky, skyDark, slate, slateDark, teal, tealDark, tomato, tomatoDark, violet, violetDark, yellow, yellowDark, } from '@radix-ui/colors';
import { colors as marvelColors } from '@marvelapp/theme';
// Replacing blackA and whiteA from Radix because their versions suck
export const blackA = {
    blackA1: 'hsl(0 0% 0% / 0.08)',
    blackA2: 'hsl(0 0% 0% / 0.17)',
    blackA3: 'hsl(0 0% 0% / 0.25)',
    blackA4: 'hsl(0 0% 0% / 0.33)',
    blackA5: 'hsl(0 0% 0% / 0.42)',
    blackA6: 'hsl(0 0% 0% / 0.50)',
    blackA7: 'hsl(0 0% 0% / 0.58)',
    blackA8: 'hsl(0 0% 0% / 0.67)',
    blackA9: 'hsl(0 0% 0% / 0.75)',
    blackA10: 'hsl(0 0% 0% / 0.83)',
    blackA11: 'hsl(0 0% 0% / 0.92)',
    blackA12: 'hsl(0 0% 0% / 1)',
};
export const whiteA = {
    whiteA1: 'hsl(0 0% 100% / 0.08)',
    whiteA2: 'hsl(0 0% 100% / 0.17)',
    whiteA3: 'hsl(0 0% 100% / 0.25)',
    whiteA4: 'hsl(0 0% 100% / 0.33)',
    whiteA5: 'hsl(0 0% 100% / 0.42)',
    whiteA6: 'hsl(0 0% 100% / 0.50)',
    whiteA7: 'hsl(0 0% 100% / 0.58)',
    whiteA8: 'hsl(0 0% 100% / 0.67)',
    whiteA9: 'hsl(0 0% 100% / 0.75)',
    whiteA10: 'hsl(0 0% 100% / 0.83)',
    whiteA11: 'hsl(0 0% 100% / 0.92)',
    whiteA12: 'hsl(0 0% 100% / 1)',
};
export const colors = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, marvelColors), { white: whiteA.whiteA12, 
    // marvelDark: blue.blue11,
    marvel: blue.blue11, marvelLight: blue.blue10, marvelExtraLight: blue.blue9, greenDark: blueDark.blue8, green: blueDark.blue7, greenLight: blueDark.blue8, greenExtraLight: blueDark.blue9, green005: blue.blue1, green008: blue.blue2, snow: slate.slate2, snowDark: slate.slate3, snowExtraDark: slate.slate4, smoke: slate.slate6, smokeDark: slate.slate7, smokeExtraDark: slate.slate8, silver: slate.slate10, slate: slate.slate11, steel: slate.slate11, black: slate.slate11, licorice: slate.slate12 }), amber), blackA), blue), crimson), cyan), grass), green), indigo), lime), mint), orange), olive), pink), plum), purple), red), sky), slate), teal), tomato), violet), whiteA), yellow), { background: whiteA.whiteA12, heading: slate.slate12, primary: blueDark.blue7, primaryHighlight: blueDark.blue8, text: slate.slate11, 
    // Make slate11 slightly darker (just in light mode)
    slate11: 'hsl(206 6.0% 32%)', 
    // TODO: uncomment this out when dark mode is fully supported
    // Dark mode
    // modes: {
    dark: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, marvelColors), { marvelDark: blueDark.blue11, marvel: blueDark.blue11, marvelLight: blueDark.blue10, marvelExtraLight: blueDark.blue9, greenDark: blue.blue8, green: blue.blue7, greenLight: blue.blue8, greenExtraLight: blue.blue9, green005: blueDark.blue1, green008: blueDark.blue2, snow: slateDark.slate2, snowDark: slateDark.slate3, snowExtraDark: slateDark.slate4, smoke: slateDark.slate6, smokeDark: slateDark.slate7, smokeExtraDark: slateDark.slate8, silver: slateDark.slate10, slate: slateDark.slate11, licorice: slateDark.slate12 }), amberDark), blackA), blueDark), crimsonDark), cyanDark), grassDark), greenDark), indigoDark), limeDark), mintDark), orangeDark), pinkDark), plumDark), purpleDark), redDark), skyDark), slateDark), tealDark), tomatoDark), violetDark), whiteA), yellowDark), { background: 'hsl(206 24.0% 6.0%)', heading: slateDark.slate12, primary: blue.blue7, primaryHighlight: blue.blue6, text: slateDark.slate11, 
        // TODO remove when we shift to the new system
        white: 'hsl(206 24.0% 6.0%)' }) });
