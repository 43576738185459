import { blue, blueDark, red, redDark, slate, slateDark, } from '@radix-ui/colors';
import { shadows as marvelShadows } from '@marvelapp/theme';
import { colors } from './colors';
export const shadows = Object.assign(Object.assign({}, marvelShadows), { 
    // New shadows
    button: Object.assign(Object.assign({}, marvelShadows.button), { disabled: `0 1px 2px 0 ${colors.blackA1}`, secondary: `0 1px 2px 0 ${colors.blackA1}`, loading: `0 1px 2px 0 ${colors.blackA1}`, secondaryHover: `0 2px 3px 0 ${colors.blackA1}`, secondaryFocus: `0 0 0 2px ${blue.blue6}`, marketing: `0 1px 2px 0 ${colors.blackA1}, 0 0 0 1px ${colors.blackA1}` }), card: Object.assign(Object.assign({}, marvelShadows.card), { default: `0 0 0 1px ${colors.blackA1}, 0 1px 2px 0 ${colors.blackA1}`, inactive: `0 0 0 1px ${colors.blackA1}, 0 1px 2px 0 ${colors.blackA1}, 0 0 0 5px ${colors.slate3}` }), dottedCard: {
        hover: `0 1px 4px 0 ${colors.blackA1}`,
    }, sidebar: {
        left: `1px 0 4px 0 ${colors.blackA1}`,
    }, typeCard: {
        active: `0 0 0 4px ${blue.blue6}`,
        skipped: `0 0 0 4px ${slate.slate6}`,
    }, brandLogo: {
        active: `0 0 0 2px ${colors.background}, 0 0 0 5px ${blue.blue6}`,
    }, input: Object.assign(Object.assign({}, marvelShadows.input), { default: `inset 0 0 0 1px ${colors.slate6}`, disabled: `inset 0 0 0 1px ${colors.slate6}`, defaultTransparent: `0 0 0 1px ${colors.blackA2}`, defaultHover: `inset 0 0 0 1px ${colors.slate7}`, defaultFocus: `0 0 0 3px ${colors.blue5}, inset 0 0 0 1px ${colors.blue8}`, focus: `0 0 0 3px ${blue.blue5}`, error: `0 0 0 3px ${red.red5}` }), borders: Object.assign(Object.assign({}, marvelShadows.borders), { top: `0 -1px 0 0 ${colors.blackA2}`, right: `1px 0 0 0 ${colors.blackA2}`, bottom: `0 1px 0 0 ${colors.blackA2}`, left: `-1px 0 0 0 ${colors.blackA2}`, black: `0 0 0 1px ${colors.blackA1}`, blackInset: `inset 0 0 0 1px ${colors.blackA1}`, inset: `inset 0 0 0 1px ${slate.slate6}`, insetLight: `inset 0 0 0 1px ${slate.slate6}` }), editable: {
        hover: `0 0 0 5px ${colors.background}, 0 0 0 8px ${slate.slate5}`,
        focus: `0 0 0 5px ${colors.background}, 0 0 0 8px ${blue.blue6}`,
    }, popover: Object.assign(Object.assign({}, marvelShadows.popover), { default: `0 0 0 1px ${colors.blackA1}, 0 8px 10px 0 ${colors.blackA1}, 0 8px 24px 0 ${colors.blackA1}`, balloon: `0 0 0 1px ${colors.blackA1}, 0 2px 4px 0 ${colors.blackA1}` }), video: {
        player: `0 6px 24px 0 ${colors.blackA4}`,
    }, currentPlan: `0 0 0 2px ${colors.slate8}`, 
    // TODO: uncomment this out when dark mode is fully supported
    // modes: {
    dark: Object.assign(Object.assign({}, marvelShadows), { 
        // New shadows
        button: {
            disabled: `0 1px 2px 0 ${colors.blackA8}`,
            secondary: `0 1px 2px 0 ${colors.blackA8}`,
            loading: `0 1px 2px 0 ${colors.blackA8}`,
            secondaryHover: `0 2px 3px 0 ${colors.blackA8}`,
            secondaryFocus: `0 0 0 2px ${blueDark.blue6}`,
            marketing: `0 1px 2px 0 ${colors.blackA1}, 0 0 0 1px ${colors.whiteA1}`,
        }, sidebar: {
            left: `1px 0 4px 0 ${colors.blackA1}`,
        }, card: Object.assign(Object.assign({}, marvelShadows.card), { default: `0 0 0 1px ${colors.blackA1}, 0 1px 2px 0 ${colors.blackA1}`, inactive: `0 0 0 1px ${colors.blackA1}, 0 1px 2px 0 ${colors.blackA1}, 0 0 0 4px ${colors.slate3}` }), typeCard: {
            active: `0 0 0 4px ${blueDark.blue6}`,
            skipped: `0 0 0 4px ${slateDark.slate6}`,
        }, input: {
            focus: `0 0 0 3px ${blueDark.blue5}`,
            error: `0 0 0 3px ${redDark.red5}`,
        }, borders: {
            top: `0 -1px 0 0 ${colors.whiteA2}`,
            right: `1px 0 0 0 ${colors.whiteA2}`,
            bottom: `0 1px 0 0 ${colors.whiteA2}`,
            left: `-1px 0 0 0 ${colors.whiteA2}`,
            inset: `inset 0 0 0 1px ${slateDark.slate6}`,
            insetLight: `inset 0 0 0 1px ${slateDark.slate6}`,
        }, editable: {
            hover: `0 0 0 5px ${colors.dark.background}, 0 0 0 8px ${slateDark.slate5}`,
            focus: `0 0 0 5px ${colors.dark.background}, 0 0 0 8px ${blueDark.blue6}`,
        }, popover: {
            default: `0 0 0 1px ${colors.whiteA1}, 0 8px 10px 0 ${colors.blackA9}, 0 8px 24px 0 ${colors.blackA9}`,
            balloon: `0 0 0 1px ${colors.whiteA2}, 0 2px 4px 0 ${colors.blackA1}`,
        }, video: {
            player: `0 6px 24px 0 ${colors.blackA4}`,
        }, currentPlan: `0 0 0 2px ${colors.dark.slate8}` }) });
