import React from 'react';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
export default function Logo({ fontSize = 'xl', }) {
    return React.createElement(Text, { fontSize: fontSize }, "Ballpark");
}
const Text = styled(Box).attrs({
    color: 'slate12',
    fontWeight: 'bold',
    hoverColor: 'slate11',
    transition: 'smooth.fast',
}) `
  outline: none;
  cursor: pointer;
  user-select: none;
  &:hover {
    transform: translate3d(0, -1px, 0);
  }
  &:active {
    transform: translate3d(0, 1px, 0);
    color: ${(props) => props.theme.colors.slate12};
  }
`;
