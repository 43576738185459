import { BAR_HEIGHT, SIDEBAR_WIDTH, containerWidths } from '@marvelapp/theme';
// TODO need to see about containerWidths because they
// only work in the context of the cards we have in mk3
export const sizes = {
    typeIcon: 42,
    shortSidebar: SIDEBAR_WIDTH,
    wideSidebar: 36 * 8,
    barHeight: BAR_HEIGHT,
    sidebarPage: {
        mobile: containerWidths[0],
        phablet: containerWidths[1],
        tablet: containerWidths[2],
        notebook: containerWidths[3],
        laptop: containerWidths[4],
        desktop: containerWidths[5],
        desktop2: containerWidths[6],
        desktop3: containerWidths[7],
        desktop4: containerWidths[8],
        desktop5: containerWidths[8],
    },
    form: {
        xs: 24,
        s: 30,
        m: 36,
        l: 40,
        xl: 46,
    },
    marketing: {
        desktop: 1180,
        max: 1380,
    },
    yesNoCheckbox: 320,
    videoGuide: {
        gutter: 12,
        mobile: 48,
        builder: 64,
        collapsed: 100,
        expanded: 220,
        editSmall: 240,
        edit: 300,
    },
    bar: {
        thin: 20,
    },
    brandLogo: 48,
};
export const radii = [
    0, 3, 4, 6, 8, 10,
];
export const space = [
    0, 4, 8, 16, 24, 32, 48, 64, 128,
];
