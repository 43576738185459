export function fetchApi(url, params = {}) {
    const { body, headers, accessToken, method, credentials } = params;
    return fetch(url, Object.assign({ body, headers: Object.assign(Object.assign({}, (accessToken ? { Authorization: `Bearer ${accessToken}` } : {})), headers), method, mode: 'cors' }, (credentials ? { credentials } : {}))).then((response) => {
        if (!response.ok && response.status === 401) {
            // TODO: refresh the access token
            window.localStorage.removeItem('accessToken');
            window.location.href = '/login';
            Promise.resolve(response);
        }
        return response.ok && response.status >= 200 && response.status < 300
            ? Promise.resolve(response)
            : Promise.reject(response);
    });
}
class ResponseError extends Error {
    constructor({ status, data }) {
        super();
        this.status = status;
        this.data = data;
    }
}
export function fetchJSON(url, params) {
    const { method, payload, accessToken, headers } = params;
    return fetchApi(url, {
        method,
        accessToken,
        headers: Object.assign({ 'Content-Type': 'application/json' }, headers),
        body: JSON.stringify(payload),
    })
        .then((response) => {
        var _a;
        if ((_a = response.headers.get('Content-Type')) === null || _a === void 0 ? void 0 : _a.startsWith('application/json')) {
            return response.json();
        }
        return {};
    })
        .catch((error) => {
        if (!(error instanceof Response)) {
            throw error;
        }
        const response = error;
        return response.json().then((data) => {
            throw new ResponseError({ status: response.status, data });
        });
    });
}
